import React, { useEffect, useState } from "react";
import {
  Select,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Col,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {
  getVisitHistoryApi,
  clearVisitsOnSearchApi,
} from "../../VisitHistory/VisitHistoryAPI";
import { useDispatch } from "react-redux";
import { getAllPartsRecordsApi } from "../../Parts/PartsAPI";
import { getEquipmentListAPI } from "../../Equipment/EquipmentList/EquipmentsAPI";
import { FormattedMessage, useIntl } from "react-intl";

const Filters = (props) => {
  const { Text } = Typography;
  const intl = useIntl();
  const [ellipsis, setEllipsis] = useState(true);
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const Option = Select.Option;
  const handleMenuClick = (e) => {
    if (e.key === "31") {
      setOpen(false);
    }
  };
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [SOType, setSOType] = useState();
  const [techName, setTechName] = useState();
  const [partsDescription, setPartsDescription] = useState();
  const [partsNumber, setPartsNumber] = useState();
  const [Tagsearch, setTagsearch] = useState();
  const [Manufsearch, setManufsearch] = useState();
  const dispatch = useDispatch();

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dates[0].set("hour", 0).set("minute", 0).set("second", 0));
      setEndDate(dates[1].set("hour", 23).set("minute", 59).set("second", 59));
    }
  };

  const handleSave = async () => {
    if (props?.screenId === "Visits") {
      var fiters = {
        sotsearch: SOType ? SOType : null,
        empsearch: techName ? techName : null,
        startDate: startDate ? startDate.format("YYYY-MM-DDTHH:mm:ssZ") : null,
        endDate: endDate ? endDate.format("YYYY-MM-DDTHH:mm:ssZ") : null,
        enableClearFilter: true,
      };
      props.filterCallback(fiters);
    } else if (props?.screenId === "Parts") {
      var fiters = {
        inventorysearch: partsNumber ? partsNumber : null,
        descriptionsearch: partsDescription ? partsDescription : null,
        enableClearFilter: true,
      };
      props.filterCallback(fiters);
    } else if (props?.screenId === "Equipment") {
      var fiters = {
        Tagsearch: Tagsearch ? Tagsearch : null,
        Manufsearch: Manufsearch ? Manufsearch : null,
        enableClearFilter: true,
      };
      props.filterCallback(fiters);
    } else {
    }
  };
  useEffect(() => {
    if (!props.ClearFilter) {
      setStartDate(null);
      setEndDate(null);
      setSOType("");
      setTechName("");
      setPartsDescription("");
      setPartsNumber("");
      setTagsearch("");
      setManufsearch("");
    }
  }, [props.ClearFilter]);

  let filterItems = [];
  if (props?.screenId === "Visits") {
    filterItems = [
      {
        label: (
          <RangePicker
            format="YYYY/MM/DD"
            onChange={onRangeChange}
            value={[startDate ? startDate : null, endDate ? endDate : null]}
            allowClear
          />
        ),
        key: "0",
      },
      {
        label: (
          <Input
            placeholder={intl.formatMessage({
              id: "Filter.ServiceOrderType",
            })}
            allowClear
            maxLength={4}
            value={SOType ? SOType : null}
            onChange={(e) => setSOType(e.target.value)}
          />
        ),
        key: "1",
      },
      {
        label: (
          <Input
            placeholder={intl.formatMessage({
              id: "Filter.TechicianName",
            })}
            allowClear
            value={techName ? techName : null}
            onChange={(e) => setTechName(e.target.value)}
          />
        ),
        key: "3",
      },
      {
        type: "divider",
      },
      {
        label: (
          <div className="text-right">
            <Button type="primary" size="small" onClick={handleSave}>
              <FormattedMessage id="Common.Search" />
            </Button>
          </div>
        ),
        key: "31",
      },
    ];
  } else if (props?.screenId === "Equipment") {
    filterItems = [
      {
        label: (
          <>
            <Row gutter={16} justify="space-around" align="middle">
              <Col span={8}>
                <Text ellipsis={ellipsis}>
                  <FormattedMessage id="Filter.TagNumber" />
                </Text>
              </Col>
              <Col span={16}>
                <Input
                  placeholder={intl.formatMessage({
                    id: "Filter.TagNumber",
                  })}
                  allowClear
                  value={Tagsearch ? Tagsearch : null}
                  onChange={(e) => setTagsearch(e.target.value)}
                />
              </Col>
            </Row>
          </>
        ),
        key: "1",
      },
      {
        label: (
          <>
            <Row gutter={16} justify="space-around" align="middle">
              <Col span={8}>
                <Text ellipsis={ellipsis}>
                  <FormattedMessage id="Filter.Manufacturer" />
                </Text>
              </Col>
              <Col span={16}>
                <Input
                  placeholder={intl.formatMessage({
                    id: "Filter.Manufacturer",
                  })}
                  allowClear
                  value={Manufsearch ? Manufsearch : null}
                  onChange={(e) => setManufsearch(e.target.value)}
                />
              </Col>
            </Row>
          </>
        ),
        key: "2",
      },
      {
        type: "divider",
      },
      {
        label: (
          <>
            <Row>
              <Col span={24} className="text-right">
                <Button type="primary" size="small" onClick={handleSave}>
                  <FormattedMessage id="Common.Search" />
                </Button>
              </Col>
            </Row>
          </>
        ),
        key: "31",
      },
    ];
  } else if (props?.screenId === "Parts") {
    filterItems = [
      {
        label: (
          <>
            <Row gutter={16} justify="space-around" align="middle">
              <Col span={8}>
                <Text ellipsis={ellipsis}>
                  <FormattedMessage id="Filter.Partsdescription" />
                </Text>
              </Col>
              <Col span={16}>
                <Input
                  placeholder={intl.formatMessage({
                    id: "Filter.Partsdescription",
                  })}
                  allowClear
                  value={partsDescription ? partsDescription : null}
                  onChange={(e) => setPartsDescription(e.target.value)}
                />
              </Col>
            </Row>
          </>
        ),
        key: "1",
      },
      {
        label: (
          <>
            <Row gutter={16} justify="space-around" align="middle">
              <Col span={8}>
                <Text ellipsis={ellipsis}>
                  <FormattedMessage id="Filter.partNumber" />
                </Text>
              </Col>
              <Col span={16}>
                <Input
                  placeholder={intl.formatMessage({
                    id: "Filter.partNumber",
                  })}
                  allowClear
                  value={partsNumber ? partsNumber : null}
                  onChange={(e) => setPartsNumber(e.target.value)}
                />
              </Col>
            </Row>
          </>
        ),
        key: "2",
      },
      {
        type: "divider",
      },
      {
        label: (
          <>
            <Row>
              <Col span={24} className="text-right">
                <Button type="primary" size="small" onClick={handleSave}>
                  <FormattedMessage id="Common.Search" />
                </Button>
              </Col>
            </Row>
          </>
        ),
        key: "31",
      },
    ];
  }
  return (
    <>
      <Dropdown
        menu={{
          items: filterItems,
          onClick: handleMenuClick,
        }}
        trigger={["click"]}
        placement="bottomRight"
        arrow
        onOpenChange={handleOpenChange}
        open={open}
      >
        <a onClick={(e) => e.preventDefault()} className="filter-icon-position">
          <i className="iconsminds-filter-2"></i>
        </a>
      </Dropdown>
    </>
  );
};
export default Filters;
