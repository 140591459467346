import { Row, Col, Input } from "antd";
import signature from "../../../src/signature.jpg";
function CustomerSignatureDelivery() {
  return (
    <>
      <div className="separator mt-2 mb-2"></div>
      <div className="equipment" style={{ paddingTop: "0" }}>
        <Row gutter={16} className="mt-2 mb-2">
          <Col span={24} md={4} xs="4">
            <label className="theme-color-new font-weight-semibold">
              PO Number
            </label>
          </Col>
          <Col span={24} sm={20}>
            <Input placeholder="PO Number" />
          </Col>
        </Row>
        <div
          className="signature text-center"
          style={{ padding: "80px 20px", border: "solid 1px #eee" }}
        >
          <img src={signature} alt="" />
        </div>
        <Row gutter={16} className="mt-2 mb-2">
          <Col span={8} sm={8} md={6} className="text-right">
            <span>Signer</span>
          </Col>
          <Col span={16} sm={16} md={8}>
            <span>
              <Input />
            </span>
          </Col>
          <Col span={24} sm={24} md={10} className="sm-mt-1 text-right">
            <span>25/10/2023 12:40</span>
          </Col>
        </Row>
        <Row gutter={16} className="mt-2 mb-2">
          <Col span={8} sm={8} md={6} className="text-right">
            <span>Delivery Method</span>
          </Col>
          <Col span={16} sm={16} md={8}>
            <span className="color-2new">
              <a href="">Email</a>
            </span>
          </Col>
          <Col span={24} sm={24} md={10} className="sm-mt-1 text-center">
            <Row className="mb-1">
              <Col flex="40px">
                <i className="iconsminds-remove text-danger"></i>
              </Col>
              <Col flex="auto">
                <Input />
              </Col>
            </Row>
            <Row className="mb-1">
              <Col flex="40px">
                <i className="iconsminds-add text-success"></i>
              </Col>
              <Col flex="auto">Add Email</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default CustomerSignatureDelivery;
