import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  customerRecords: [],
  customerLocationRecords: [],
  CustomerprojectRecords: [],
  customerDefaultProject: [],
  serviceItemsList: [],
};
const CreateAppointmentSlice = createSlice({
  name: "CreateAppointment",
  initialState,
  reducers: {
    getCustomerListRecords: (state, action) => {
      state.customerRecords = action.payload;
    },
    getCustomerLocationRecords: (state, action) => {
      state.customerLocationRecords = action.payload.locations;
    },
    getProjectRecords: (state, action) => {
      state.CustomerprojectRecords = action.payload;
    },
    getDefaultProjectRecords: (state, action) => {
      state.customerDefaultProject = action.payload;
    },
    getServiceItemRecords: (state, action) => {
      state.serviceItemsList = action.payload;
    },
    resetState(state) {
      state.customerRecords = [];
      state.customerLocationRecords = [];
      state.CustomerprojectRecords = [];
      state.customerDefaultProject = [];
      state.serviceItemsList = [];
    },
  },
});
export const {
  getCustomerListRecords,
  getCustomerLocationRecords,
  getProjectRecords,
  getDefaultProjectRecords,
  getServiceItemRecords,
  resetState,
} = CreateAppointmentSlice.actions;
export default CreateAppointmentSlice.reducer;
