import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
};

const GlobalSpinnerSlice = createSlice({
  name: "GlobalSpinner",
  initialState,
  reducers: {
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { showLoader, hideLoader } = GlobalSpinnerSlice.actions;

export default GlobalSpinnerSlice.reducer;
