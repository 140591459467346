import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  VisitHistory: [],
  VisitHistoryDetails: {},
  appointmentDetails: {},
  loader: false,
  pageNo: 1,
};

const VisitHistory = createSlice({
  name: "VisitHistory",
  initialState,
  reducers: {
    VisitHistoryListSuccess(state, action) {
      state.VisitHistory = [...state.VisitHistory, ...action.payload];
    },
    VisitHistoryDetails(state, action) {
      state.VisitHistoryDetails = action.payload;
    },
    getAppointmentDetails(state, action) {
      state.appointmentDetails = action.payload;
    },
    showLocalLoader(state) {
      state.loader = true;
    },
    hideLocalLoader(state) {
      state.loader = false;
    },
    resetState(state) {
      state.VisitHistory = [];
      state.appointmentDetails = {};
    },
    resetOnSearch(state) {
      state.VisitHistory = [];
    },
    updatePgNumber(state, action) {
      state.pageNo = action.payload;
    },
  },
});
export const {
  VisitHistoryListSuccess,
  VisitHistoryDetails,
  getAppointmentDetails,
  showLocalLoader,
  hideLocalLoader,
  resetState,
  resetOnSearch,
  updatePgNumber,
} = VisitHistory.actions;
export default VisitHistory.reducer;
