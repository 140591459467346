import { getRequest, postRequest, deleteRequest } from "app/axiosClient";
import { message } from "antd";
import {
  getWorkdoneRecords,
  getProjectTaskList,
  resetState,
} from "./WorkDoneSlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getWorkdoneRecordsApi = (path) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/WorkDone/WorkDoneDetails?appointmentNbr=${path}`).then(
      (res) => {
        dispatch(getWorkdoneRecords(res.data));
        dispatch(hideLoader());
      }
    );
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "workdone.Unabletofetchworkdone",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};
export const getProjectTaskListAPi = (projectpath) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/getProjectTaskList?projectID=${projectpath}`).then(
      (res) => {
        dispatch(getProjectTaskList(res.data));
        dispatch(hideLoader());
      }
    );
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "workdone.UnablefetchProjecttasklist",
        type: "error",
      })
    );

    dispatch(hideLoader());
  }
};
export const UpdateWorkDoneRecordsApi = (values) => (dispatch) => {
  const input = `${values.appointmentNbr}&noteID=${values.id}`;
  try {
    dispatch(showLoader());
    postRequest(`api/WorkDone/UpdateWorkDone`, values).then((res) => {
      //dispatch(getWorkdoneRecordsApi(input));
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "workdone.WorkdoneUpdatedSuccessfully",
          type: "success",
        })
      );
    });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "workdone.Unableupdateworkdonedetails",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};
export const clearWorkDoneState = () => (dispatch) =>
  Promise.all([dispatch(resetState())]);
