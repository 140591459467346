import { Alert, Spin, Switch } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
function GlobalSpinner(props) {
  const { loader } = useSelector((state) => state.GlobalSpinner);
  return (
    <>
      <div className="height100vh">
        <Spin spinning={loader} size="large">
          {props.appContainer}
        </Spin>
      </div>
    </>
  );
}
export default GlobalSpinner;
