import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import { getAttachments } from "./AttachmentsSlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getAttachmentsList = (appointmentId) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(
      `api/Attachments/GetAttachmentsList?appointmentId=${appointmentId}`
    )
      .then((res) => {
        dispatch(getAttachments(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        // dispatch(
        //   showNotification({
        //     show: true,
        //     messageId: "Visits.Unablefetchdata",
        //     type: "error",
        //   })
        // );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Visits.Unablefetchdata",
        type: "error",
      })
    );
  }
};

export const uploadImages =
  (input, appointmentid, setSaveDisable) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Attachments/UploadAttachments`, input)
        .then((res) => {
          dispatch(hideLoader());
          dispatch(getAttachmentsList(appointmentid));
          setSaveDisable(true);
          dispatch(
            showNotification({
              show: true,
              messageId: "Attachment.ImageUploadSuccess",
              type: "success",
            })
          );
        })
        .catch((e) => {
          dispatch(hideLoader());
          // dispatch(
          //   showNotification({
          //     show: true,
          //     messageId: "Attachment.UnableToDownload",
          //     type: "error",
          //   })
          // );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Attachment.UnableToDownload",
          type: "error",
        })
      );
    }
  };

export const deleteImagesApi = (input) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Attachments/DeleteAttachments`, input)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(getAttachmentsList(input?.noteId));
        dispatch(
          showNotification({
            show: true,
            messageId: "Attachment.ImageDeleteSuccess",
            type: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(hideLoader());
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Attachment.UnableToDelete",
        type: "error",
      })
    );
  }
};
