import axios from "axios";
import constants from "common/utils/constants";
import { useDebugValue } from "react";
import { message } from "antd";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = constants.HOST_URL;

axiosClient.defaults.headers = constants.headers;

// To share cookies to cross site domain, change to true.
axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.request.use(function (config) {
  var token = localStorage.getItem("access_token");
  var token_type = localStorage.getItem("token_type");
  var authHeader = `${token_type} ${token}`;
  config.headers.Authorization = authHeader;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response?.status != null &&
      error?.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const userName = localStorage.getItem("userName");
        const response = await axiosClient.post(
          `/api/Login/GetTokenByRefreshToken?refresh_token=${refreshToken}&userName=${userName}`
        );
        var token_type = localStorage.getItem("token_type");
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("token_type", response.data.token_type);
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + 3600 * 1000;
        const expirationDateTime = new Date(expirationTime);
        localStorage.setItem("expires", expirationDateTime);
        delete originalRequest.headers.Authorization;
        originalRequest.headers.Authorization = `${token_type} ${response.data.access_token}`;
        return axiosClient(originalRequest);
      } catch (error) {
        //The page will be rediected to Login page.
        localStorage.clear();
        window.location.reload();
      }
    } else if (
      error?.response?.status != null &&
      error.response.status === 400 &&
      error.response.data.errorCode == 503
    ) {
      window.location.href = "/maintenancemode";
    } else if (
      error?.response?.status != null &&
      error.response.status === 400 &&
      error.response.data.errorCode == 500 &&
      error.response.data.message.includes("exceptionMessage")
    ) {
      const jsonPattern = /\{[^}]+\}/;
      const matches = error.response.data.message.match(jsonPattern);
      if (matches && matches[0]) {
        const errorMessageObj = JSON.parse(matches[0]);
        const exceptionMessage = errorMessageObj?.exceptionMessage;
        if (exceptionMessage) {
          message.error(exceptionMessage);
        } else {
          message.error(error.response.data.message);
        }
      } else {
        message.error(error.response.data.message);
      }
    } else {
      if (
        error?.response?.status != null &&
        error.response.status === 400 &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else if (
        error?.response?.status != null &&
        error.response.status === 400 &&
        error.response.data.errorCode === 400
      ) {
        message.error(
          error.response.data.message ||
            "An unexpected error occurred. Please try again."
        );
      } else {
        message.error("S'il vous plaît essayez après un certain temps");
        console.log("Error500:", error?.response?.data?.message, error);
      }
      return Promise.reject(error);
    }
  }
);

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
