import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import {
  equipmentDetailsSuccess,
  getTasksRecords,
  showUpdateButton,
  hideUpdateButton,
  clearTasksRecords,
} from "./EquipmentDetailsSlice";
import {} from "./EquipmentDetailsSlice";
import { showResultPopupMessage } from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getEquipmentDetailsAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return getRequest(
      `api/Equipment/GetEquipmentDetails?equipmentId=${payload}`
    )
      .then((res) => {
        dispatch(equipmentDetailsSuccess(res.data));
        dispatch(hideLoader());
        return true;
      })
      .catch((e) => {
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
            type: "error",
          })
        );

        return false;
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
        type: "error",
      })
    );
    return false;
  }
};

export const updateLocationAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return postRequest(`api/Equipment/UpdateEquipmentLocation`, payload)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "EquipmentDetails.LocationUpdatedSuccessfully",
            type: "success",
          })
        );

        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "EquipmentDetails.LocationUpdateFailed",
            type: "error",
          })
        );

        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "EquipmentDetails.LocationUpdateFailed",
        type: "error",
      })
    );
    return false;
  }
};

export const updateSpecAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return postRequest(`api/Equipment/UpdateEquipmentSpecs`, payload)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "EquipmentDetails.SpecUpdatedSuccessfully",
            type: "success",
          })
        );
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "EquipmentDetails.SpecUpdatedFailed",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());

    dispatch(
      showNotification({
        show: true,
        messageId: "EquipmentDetails.SpecUpdatedFailed",
        type: "error",
      })
    );
    return false;
  }
};

export const getTasksByEquipmentIdApi =
  (appointmentNbr, equipmentId) => (dispatch) => {
    try {
      dispatch(showLoader());
      getRequest(
        `api/Tasks/GetTasksByEquipmentID?appoinmentNbr=${appointmentNbr}&equipmentID=${equipmentId}`
      )
        .then((res) => {
          dispatch(getTasksRecords(res.data));
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Visits.Unablefetchdata",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Visits.Unablefetchdata",
          type: "error",
        })
      );
    }
  };

export const getTasksFromServiceTemplateByEquipmentIdApi =
  (appointmentNbr, equipmentId) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(
        `api/Tasks/ServiceTaskList?appointmentNbr=${appointmentNbr}&equipmentID=${equipmentId}`
      )
        .then((res) => {
          dispatch(getTasksRecords(res.data));
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Visits.Unablefetchdata",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Visits.Unablefetchdata",
          type: "error",
        })
      );
    }
  };

export const updateServiceTemplateTaskApi =
  (input, appointmentNbr, equipmentId) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Tasks/MarkServiceOrderTemplateCompleted`, input)
        .then((res) => {
          // dispatch(
          //   getTasksFromServiceTemplateByEquipmentIdApi(
          //     appointmentNbr,
          //     equipmentId
          //   )
          // );
          dispatch(hideLoader());
          dispatch(hideUpdateButton());
          dispatch(
            showNotification({
              show: true,
              messageId: "EquipmentDetails.Taskupdatedsuccessfully",
              type: "success",
            })
          );
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Visits.Unableupdatetask",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Visits.Unableupdatetask",
          type: "error",
        })
      );
    }
  };

export const updateTaskStatusApi = (input, equipmentId) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Tasks/UpdateTasksStatus`, input)
      .then((res) => {
        //dispatch(getTasksByEquipmentIdApi(input.appointmentNbr, equipmentId));
        dispatch(hideLoader());
        dispatch(hideUpdateButton());
        dispatch(
          showNotification({
            show: true,
            messageId: "EquipmentDetails.Taskupdatedsuccessfully",
            type: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Visits.Unableupdatetask",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Visits.Unableupdatetask",
        type: "error",
      })
    );
  }
};

export const clearTaskRecordsStateApi = () => (dispatch) => {
  dispatch(clearTasksRecords());
};
