import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import { getSignatureRecords, resetState } from "./CaptureSignatureSlice";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { updateAppointmentDetails } from "../Actions/ActionsAPI";
import { showNotification } from "../../CommonComponents/NofificationMessages/NofificationMessagesSlice";
import { getAttachmentsList } from "features/Attachments/AttachmentsAPI";
export const updateSignatureAPI = (values) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Appointment/CaptureSignature`, values)
      .then((res) => {
        var request = {
          appointmentNbr: values.appointmentNbr,
          appointmentNoteId: values.NoteID,
        };
        dispatch(hideLoader());
        dispatch(updateAppointmentDetails(request));
        dispatch(getAttachmentsList(values.NoteID));
        dispatch(
          showNotification({
            show: true,
            messageId: "CaptureSignature.SuccessfullySavedSignature",
            type: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CaptureSignature.UnableSaveSignature",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "CaptureSignature.UnableSaveSignature",
        type: "error",
      })
    );
  }
};
