import { Button, Result, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showOrHideResultPopup } from "../ResultPopup/ResultPopupSlice";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
function ResultPopup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const popupData = useSelector((state) => state.ResultPopup);
  const handleOk = () => {
    dispatch(showOrHideResultPopup(false));
  };

  const handleTryAgain = () => {
    dispatch(popupData.callBack);
    dispatch(showOrHideResultPopup(false));
  };

  const handleBackButtonClick = () => {
    dispatch(showOrHideResultPopup(false));
    history.push(popupData.backButtonRedirectPath);
  };

  return (
    <>
      <Modal
        //title="success"
        open={popupData.isModalOpen}
        footer={null}
        //onOk={handleOk}
        onCancel={handleOk}
      >
        <Result
          status={popupData.status}
          title={popupData.title}
          subTitle={popupData.subTitle}
          extra={[
            <div key="buttons">
              {/* <Button
                type="primary"
                key="console"
                onClick={handleOk}
                name={"OK"}
              >
                Okay
              </Button>
              <br></br>
              <Button
                className="mt-12"
                type="primary"
                key="console"
                onClick={handleBackButtonClick}
                name={popupData.backButtonName}
                display= {"none"}
              >
                {popupData.backButtonName}
              </Button> */}
              <Button
                className="mt-12"
                type="primary"
                key="console"
                onClick={handleTryAgain}
                visible={popupData.showReloadButton}
              >
                Try again
              </Button>
            </div>,
          ]}
        />
      </Modal>
    </>
  );
}

export default ResultPopup;
