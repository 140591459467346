import { lazy } from "react";

const routes = [
  {
    path: "dashboard",
    component: lazy(() => import("features/Dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "appointment",
    component: lazy(() => import("features/Dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid/summary",
    //path: "summary/:appointmentId",
    component: lazy(() => import("features/Summary/Summary")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/equipments",
    component: lazy(() => import("features/Equipment/EquipmentList/Equipment")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/equipment/:equipmentid?",
    component: lazy(() =>
      import("features/Equipment/EquipmentDetails/EquipmentDetails")
    ),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/labour",
    component: lazy(() => import("features/Labour/Labour")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/labour/:labourid",
    component: lazy(() => import("features/Labour/Components/LabourDetails")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/parts",
    component: lazy(() => import("features/Parts/Parts")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/part/:partid?",
    component: lazy(() => import("features/Parts/Components/PartDetails")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/refrigerant",
    component: lazy(() => import("features/Refrigerant/Refrigerant")),
    exact: true,
  },
  {
    path: "refrigerantdetails",
    component: lazy(() => import("features/Refrigerant/RefrigerantDetails")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/tasks",
    component: lazy(() => import("features/Tasks/Tasks")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/othercost",
    component: lazy(() => import("features/Othercost/Othercost")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid/visithistory",
    component: lazy(() =>
      import("features/VisitHistory/VisitHistoryDetails/VisitHistoryDetails")
    ),
    exact: true,
  },
  {
    path: "appointment/:appointmentid/visithistory/:visithistoryid",
    component: lazy(() =>
      import("features/VisitHistory/VisitHistoryDetails/VisitHistoryDetails")
    ),
    exact: true,
  },
  {
    path: "othercostdetails",
    component: lazy(() => import("features/Othercost/OthercostDetails")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/workdone",
    component: lazy(() => import("features/WorkDone/WorkDone")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/attachments",
    component: lazy(() => import("features/Attachments/Attachments")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/stage",
    component: lazy(() => import("features/Stage/Stage")),
    exact: true,
  },
  {
    path: "appointment/:appointmentid?/additionalwork",
    component: lazy(() => import("features/Additionalwork/Additionalwork")),
    exact: true,
  },
  {
    path: "preventative_maintenance",
    component: lazy(() =>
      import("features/PreventativeMaintenance/PreventativeMaintenance")
    ),
    exact: true,
  },
  {
    path: "preapproved_quote",
    component: lazy(() => import("features/PreApprovedQuote/PreApprovedQuote")),
    exact: true,
  },
  {
    path: "servicecalltime",
    component: lazy(() => import("features/ServiceCallTime/ServiceCallTime")),
    exact: true,
  },
  {
    path: "order",
    component: lazy(() => import("features/Order/Order")),
    exact: true,
  },
];

export default routes;
