import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  equipmentList: [],
  availableList: [],
  servicedList: [],
  equipmentTypeList: [],
  targetEquipmentList: [],
  equipmentsForCustomer: [],
};

const EquipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    equipmentListSuccess(state, action) {
      state.equipmentList = action.payload;

      let servicedList = [];
      let availableList = [];
      action.payload.forEach((element) => {
        if (
          element.completedTasks == element.totalTasks &&
          element.completedTasks > 0
        ) {
          servicedList.push(element);
        } else {
          availableList.push(element);
        }
      });
      state.availableList = availableList;
      state.servicedList = servicedList;
    },
    equipmentTypeListSuccess(state, action) {
      state.equipmentTypeList = action.payload;
    },
    targetEquipmentListSuccess(state, action) {
      state.targetEquipmentList = action.payload;
    },
    getEquipmentsForCustomer(state, action) {
      state.equipmentsForCustomer = action.payload;
    },
    resetState(state) {
      state.equipmentList = [];
      state.equipmentTypeList = [];
      state.targetEquipmentList = [];
      state.availableList = [];
      state.servicedList = [];
      state.equipmentsForCustomer = [];
    },
  },
});

export const {
  equipmentListSuccess,
  equipmentTypeListSuccess,
  targetEquipmentListSuccess,
  getEquipmentsForCustomer,
  resetState,
} = EquipmentsSlice.actions;
export default EquipmentsSlice.reducer;
