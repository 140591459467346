import { createSlice } from "@reduxjs/toolkit";
export const WorkDoneSlice = createSlice({
  name: "WorkDoneDetails",
  initialState: {
    workdoneRecords: [],
    projectTaskRecords: [],
  },
  reducers: {
    getWorkdoneRecords: (state, action) => {
      state.workdoneRecords = action.payload;
    },
    getProjectTaskList: (state, action) => {
      state.projectTaskRecords = action.payload;
    },
    resetState(state) {
      state.workdoneRecords = [];
      state.projectTaskRecords = [];
    },
  },
});
export const { getWorkdoneRecords, getProjectTaskList, resetState } =
  WorkDoneSlice.actions;
export default WorkDoneSlice.reducer;
