import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { showNotification } from "./NofificationMessagesSlice";
function NofificationMessages() {
  const [messageApi, contextHolder] = message.useMessage();
  const notification = useSelector((state) => state.NofificationMessages);
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    var a = notification;
    if (notification.show) {
      if (notification.type == "success")
        message.success(intl.formatMessage({ id: notification.messageId }));
      else if (notification.type == "error")
        message.error(intl.formatMessage({ id: notification.messageId }));
      else if (notification.type == "warning")
        message.error(intl.formatMessage({ id: notification.messageId }));
    }

    dispatch(showNotification({ show: false, messageId: "", type: "" }));
  }, [notification]);

  return <>{/* <p></p> */}</>;
}

export default NofificationMessages;
