import { Table, Row, Col, Input } from "antd";
const { Column } = Table;
const { TextArea } = Input;
const data = [
  {
    key: "1",
    value1: "1581021734",
    value2: "AIR HSNDLING UNIT",
    value3: "Trane",
    value4: "6789-9876",
    value5: "78787878787878",
    value6: "Balance Air",
    value7: "Work Done Recorded",
    value8: "Additional work recommended",
  },
  {
    key: "2",
    value1: "1581021734",
    value2: "AIR HSNDLING UNIT",
    value3: "Trane",
    value4: "6789-9876",
    value5: "78787878787878",
    value6: "Balance Air",
    value7: "Work Done Recorded",
    value8: "Additional work recommended",
  },
];
function CustomerApprovalForm() {
  return (
    <>
      <div className="separator mt-2 mb-2"></div>
      <div className="equipment" style={{ paddingTop: "0" }}>
        <Table
          dataSource={data}
          size="small"
          pagination={false}
          scroll={{
            x: 600,
          }}
        >
          <Column
            dataIndex="EquipmentType"
            key="EquipmentType"
            render={(text, record) => (
              <>
                <span>
                  {record.value1}
                  <br />
                  {record.value2}
                </span>
              </>
            )}
          />
          <Column
            dataIndex="manufacture"
            key="manufacture"
            render={(text, record) => (
              <>
                <span>
                  {record.value3}
                  <br />
                  {record.value4}
                  <br />
                  {record.value5}
                </span>
              </>
            )}
          />
          <Column
            dataIndex="manufacture1"
            key="manufacture1"
            render={(text, record) => (
              <>
                <span>
                  {record.value6}
                  <br />
                  {record.value7}
                  <br />
                  {record.value8}
                </span>
              </>
            )}
          />
        </Table>
        <Row gutter={16} className="mt-2 mb-2">
          <Col span={24} sm={4}>
            <label className="theme-color-new font-weight-semibold">
              Work Done
            </label>
          </Col>
          <Col span={24} sm={20}>
            <TextArea
              rows={3}
              value={
                "Started scheduled planned maintenanace will return to complete PM. Lorem Ipsum is simply dummy text of the printing"
              }
            />
          </Col>
        </Row>
        <Row gutter={16} className="mb-2">
          <Col span={24} sm={4}>
            <label className="theme-color-new font-weight-semibold">
              Add'l Work
            </label>
          </Col>
          <Col span={24} sm={20}>
            <TextArea rows={3} value={"Please quote the following repairs"} />
          </Col>
        </Row>
      </div>
    </>
  );
}
export default CustomerApprovalForm;
