import authentication from "features/OnBoarding/authenticationSlice";
import dashboard from "features/Dashboard/DashboardSlice";
import ResultPopup from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import NofificationMessages from "features/CommonComponents/NofificationMessages/NofificationMessagesSlice";
import GlobalSpinner from "features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import AppointmentsSlice from "features/Appointments/AppointmentList/AppointmentsSlice";
import EquipmentsSlice from "features/Equipment/EquipmentList/EquipmentsSlice";
import EquipmentDetailsSlice from "features/Equipment/EquipmentDetails/EquipmentDetailsSlice";
import Summary from "features/Summary/SummarySlice";
import Parts from "features/Parts/PartsSlice";
import ActionsSlice from "features/Appointments/Actions/ActionsSlice";
import VisitHistory from "features/VisitHistory/VisitHistorySlice";
import WorkDoneDetails from "features/WorkDone/WorkDoneSlice";
import AdditionalWork from "features/Additionalwork/AdditionalWorkSlice";
import Labour from "features/Labour/LabourSlice";
import Tasks from "features/Tasks/TasksSlice";
import Stage from "features/Stage/StageSlice";
import CreateAppointment from "features/Appointments/AddAppointment/CreateAppointmentSlice";
import Attachments from "../features/Attachments/AttachmentsSlice";
import Signature from "../features/Appointments/CaptureSignature/CaptureSignatureSlice";
//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  dashboard,
  ResultPopup,
  NofificationMessages,
  GlobalSpinner,
  AppointmentsSlice,
  EquipmentsSlice,
  EquipmentDetailsSlice,
  Summary,
  Parts,
  ActionsSlice,
  VisitHistory,
  WorkDoneDetails,
  AdditionalWork,
  Labour,
  Tasks,
  Stage,
  CreateAppointment,
  Attachments,
  Signature,
};

export default rootReducer;
