import React, { useState, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown, Col, Menu, Row, Button, Tooltip, message } from "antd";
import {
  UndoOutlined,
  RedoOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setBlankImage } from "features/Appointments/CaptureSignature/CaptureSignatureSlice";
// https://www.npmjs.com/package/react-sketch-canvas
// https://vinoth.info/react-sketch-canvas/
// http://casesandberg.github.io/react-color/
function SketchCanvas(props) {
  var canvas = React.createRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [penColor, setPenColor] = useState("#000000");
  const [penStrokeWidth, setPenStrokeWidth] = useState(3);
  const [backgroundImage, setBackgroundImage] = useState(props.backgroundImage);
  const [clearImage, setClearImage] = useState(false);
  const [signImage, setSignImage] = useState({ data: null });
  const { blankBase64 } = useSelector((state) => state.Signature);

  const onClick = ({ key }) => {
    setPenStrokeWidth(key);
  };
  // const onSave=()=>{
  //   props.setImage(data);
  useEffect(() => {
    canvas.current.exportImage("png").then((data) => {
      if (!blankBase64) {
        dispatch(setBlankImage(data));
      }
    });
  }, []);
  // }
  // useEffect(() => {
  //   //props.setImage(props.backgroundImage);
  //   setBackgroundImage(props.backgroundImage);
  // }, [props.backgroundImage]);
  // useEffect(() => {
  //   if (props.key) {
  //     setClearImage(true);
  //   }
  // }, [props.key]);
  // useEffect(() => {
  //   props.ifCanceled && setClearImage(false);
  // }, [props.ifCanceled]); //To Prevent(In Bp_Diagram if the image is just cleared without save -> cancel and open -> image not showing)

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="1">
        <span>
          1px <hr width="10px" style={{ border: "1px solid" }} />
        </span>
      </Menu.Item>
      <Menu.Item key="3">
        3px <hr width="10px" style={{ border: "3px solid" }} />
      </Menu.Item>
      <Menu.Item key="5">
        5px <hr width="10px" style={{ border: "5px solid" }} />
      </Menu.Item>
      <Menu.Item key="8">
        8px <hr width="10px" style={{ border: "8px solid" }} />
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="drawing-component">
        <ReactSketchCanvas
          id={props.id}
          width="1000"
          height={props.height}
          ref={canvas}
          style={{ border: "solid 1px #000" }}
          strokeWidth={penStrokeWidth}
          strokeColor={penColor}
          // onChange={onChange}
          backgroundImage={clearImage ? "" : backgroundImage}
        />
        <Row gutter={4} align="middle" style={{ marginTop: "10px" }}>
          {/* <Col flex="25px">
            <Tooltip title="Color Picker">
              <input
                type="color"
                style={{
                  width: "25px",
                  height: "25px",
                  border: "none",
                }}
                value={penColor}
                onChange={(e) => {
                  setPenColor(e.target.value);
                }}
              />
            </Tooltip>
          </Col> */}
          {/* <Col flex="50px">
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <hr
                  style={{
                    border: `${penStrokeWidth}px solid`,
                    color: "black",
                  }}
                />
              </a>
            </Dropdown>
          </Col> */}
          <Col flex="110px" className="drawing-btn">
            <Tooltip title={<FormattedMessage id="SketchCanvas.ClearAll" />}>
              <Button
                type="link"
                style={{ paddingLeft: "0" }}
                onClick={() => {
                  setClearImage(true);
                  canvas.current.clearCanvas();
                  props.setSignAvailable(false);
                  props.setIfCanceled && props.setIfCanceled(false); //To Prevent(In Bp_Diagram if the image is just cleared without save -> cancel and open -> image not showing)
                  setBackgroundImage();
                  props.setImage({ data: "" });
                }}
              >
                <i
                  className="iconsminds-eraser-2"
                  style={{ fontSize: "18px" }}
                ></i>
                &nbsp;
                <FormattedMessage id="SketchCanvas.Erase" />
              </Button>
            </Tooltip>
          </Col>
          <Col flex="auto" className="drawing-save text-right">
            <Button
              type="primary"
              onClick={() => {
                canvas.current
                  .exportImage("png")
                  .then((data) => {
                    // Ensure immutability by creating a new object
                    props.setImage({ data });
                  })
                  .catch((e) => {
                    message.error(
                      intl.formatMessage({
                        id: "Common.Unabletosave",
                      })
                    );
                  });
                props.setSignAvailable(true);
              }}
            >
              <FormattedMessage id="Common.Save" />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SketchCanvas;
