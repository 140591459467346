import React, { Suspense, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Select, Input, Form, message } from "antd";
import {
  getAllCustomerListApi,
  getAllCustomerLocationListApi,
  getCustomerProjectsListApi,
  getCustomerDefaultProjectRecords,
  getNonStockItemRecordsApi,
  addNewAppointmentAPI,
} from "../AddAppointment/CreateAppointmentAPI";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
function CreateAppointment(props) {
  const intl = useIntl();
  const Option = Select.Option;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const { customerRecords } = useSelector((state) => state.CreateAppointment);
  const { customerLocationRecords } = useSelector(
    (state) => state.CreateAppointment
  );
  const { CustomerprojectRecords } = useSelector(
    (state) => state.CreateAppointment
  );
  const { customerDefaultProject } = useSelector(
    (state) => state.CreateAppointment
  );
  const { serviceItemsList } = useSelector((state) => state.CreateAppointment);
  const { appointmentList } = useSelector((state) => state.AppointmentsSlice);
  var empId = localStorage.getItem("employeeId");

  useEffect(() => {
    dispatch(getAllCustomerListApi());
  }, [dispatch]);
  const [SrvOrdtypeSelected, setSrvOrdtypeSelected] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedDefaultProject, setSelectedDefaultProject] = useState();
  const [locationDisable, setLocationDisable] = useState(true);
  const [defaultProjDisable, setDefaultProjDisable] = useState(true);
  const [customerDisable, setCustomerDisable] = useState(true);
  const [isrequired, setIsrequired] = useState();
  const excludeServiceItems = ["S.TEC OVERHEAD", "S.TEC CCQ OVERHEAD"];
  const handleSrvOrdtypeSelected = (value, option, allRecords) => {
    if (value == undefined) {
      const SrvOrdtypeSelected = allRecords.find(
        (record) => record.value === value
      );
      setCustomerDisable(true);
      setSrvOrdtypeSelected(SrvOrdtypeSelected);
    } else {
      const SrvOrdtypeSelected = allRecords.find(
        (record) => record.value === value
      );
      setCustomerDisable(false);
      setSrvOrdtypeSelected(SrvOrdtypeSelected);
    }
  };

  const handleCustomerSelected = (value, option, allRecords) => {
    if (value === undefined) {
      setLocationDisable(true);
      setSelectedRecord(null);
      form.setFieldsValue({
        defaultProjectTask: undefined,
        projectSO: undefined,
        location: undefined,
        inventoryID: undefined,
      });
    } else {
      const selectedRecord = allRecords.find(
        (record) => record.customerID === value
      );
      setSelectedRecord(selectedRecord);
      form.setFieldsValue({
        defaultProjectTask: undefined,
        projectSO: undefined,
        location: undefined,
        inventoryID: undefined,
      });
    }
  };

  useEffect(() => {
    if (selectedRecord != null || selectedRecord != undefined) {
      setLocationDisable(false);
      dispatch(getAllCustomerLocationListApi(selectedRecord.customerID));
      dispatch(getCustomerProjectsListApi(selectedRecord.customerID));
      dispatch(getNonStockItemRecordsApi("Service"));
    }
  }, [selectedRecord]);
  const handleLocationSelected = (value, option, allRecords) => {
    const selectedLocation = allRecords.find(
      (record) => record.locationID === value
    );
    setSelectedLocation(selectedLocation);
  };

  const handleProjectSelected = (value, option, allRecords) => {
    const selectedProject = allRecords.find(
      (record) => record.projectID === value
    );
    if (
      selectedProject?.projectID === "X" ||
      selectedProject?.projectID == undefined
    ) {
      setIsrequired(false);
      setDefaultProjDisable(true);
    } else {
      setIsrequired(true);
      setDefaultProjDisable(false);
    }
    setSelectedProject(selectedProject);
  };
  useEffect(() => {
    if (selectedProject != null || selectedProject != undefined) {
      if (selectedProject.projectID != "X") {
        dispatch(getCustomerDefaultProjectRecords(selectedProject?.projectID));
      }
    }
  }, [selectedProject]);
  const handleDefaultProjectSelected = (value, option, allRecords) => {
    const selectedDefaultProject = allRecords.find(
      (record) => record.projectTaskID === value
    );
    setSelectedDefaultProject(selectedDefaultProject);
  };
  const serviceOrderTypes = [
    {
      Name: "TMVT",
      value: "TMVT",
      description: "Temps et Matériels Ventilation",
    },
    {
      Name: "TMCB",
      value: "TMCB",
      description: "Temps et Matériels Combustion",
    },
    {
      Name: "TMCT",
      value: "TMCT",
      description: "Temps et Matériels Contrôle",
    },
  ];
  const handleInventorySelected = (value, option, allRecords) => {
    const serviceItemsList = allRecords.find(
      (record) => record.projectTaskID === value
    );
    setSelectedDefaultProject(selectedDefaultProject);
  };
  const handelCreateApp = async () => {
    try {
      const values = await form.validateFields();

      const details = [
        {
          lineType: "Service",
          inventoryID: values.inventoryID,
          staffMemberID: localStorage.getItem("employeeId"),
        },
      ];

      const payload = {
        serviceOrderType: values.serviceOrderType,
        customer: values.customer,
        location: values.location,
        branchLocation: null,
        projectSO: values.projectSO,
        defaultProjectTask: values.defaultProjectTask,
        problemDescription: values.problemDescription,
        details: details,
        StaffMember: localStorage.getItem("employeeId"),
      };

      dispatch(addNewAppointmentAPI(payload, refreshURL));
      props.setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error("Validation failed", error);
    }
  };
  const refreshURL = (noteID) => {
    noteID != null
      ? history.push(`/appointment/${noteID}/summary`)
      : history.push(`/dashboard`);
  };
  const commonFilterOption = (input, option) => {
    const optionLabel =
      (option.children && option.children.toString().toUpperCase()) ||
      (option.label && option.label.toString().toUpperCase()) ||
      (option.value &&
        option.value.description &&
        option.value.description.toUpperCase());

    return optionLabel.indexOf(input.toUpperCase()) >= 0;
  };
  useEffect(() => {
    if (props.createAppFormReset) {
      form.resetFields();
    }
  }, [props]);
  return (
    <>
      <Form
        form={form}
        name="basic"
        layout="horizontal"
        labelWrap
        colon={false}
        className="mt-20 form-new-kolotech"
        labelCol={{
          span: 6,
          xs: 8,
          md: 8,
          lg: 6,
          xl: 6,
        }}
        wrapperCol={{
          span: 18,
          xs: 16,
          md: 18,
          lg: 18,
          xl: 17,
        }}
        initialValues={{
          remember: false,
        }}
      >
        <Form.Item
          label={<FormattedMessage id="Appointment.ServiceOrderType" />}
          name="serviceOrderType"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectServiceOrderType" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectServiceOrderType" />
            }
            allowClear
            filterOption={commonFilterOption}
            onChange={(value, option) =>
              handleSrvOrdtypeSelected(value, option, serviceOrderTypes)
            }
          >
            {serviceOrderTypes.map((record) => (
              <Option key={record.value} value={record.value}>
                {`${record.value} - ${record.description}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="Appointment.Customer" />}
          name="customer"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectCustomer" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectCustomer" />
            }
            allowClear
            required={true}
            disabled={customerDisable}
            filterOption={commonFilterOption}
            onChange={(value, option) =>
              handleCustomerSelected(value, option, customerRecords)
            }
          >
            {customerRecords.map((record) => (
              <Option key={record.customerID} value={record.customerID}>
                {`${record.customerID} - ${record.customerName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id="Equipments.Location" />}
          name="location"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectLocation" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectLocation" />
            }
            allowClear
            disabled={locationDisable}
            onChange={(value, option) =>
              handleLocationSelected(value, option, customerLocationRecords)
            }
            filterOption={commonFilterOption}
          >
            {customerLocationRecords.map((record) => (
              <Option key={record.locationID} value={record.locationID}>
                {`${record.locationID} - ${record.locationName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="Appointment.Project" />}
          name="projectSO"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectProject" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectProject" />
            }
            allowClear
            disabled={locationDisable}
            filterOption={commonFilterOption}
            onChange={(value, option) =>
              handleProjectSelected(value, option, CustomerprojectRecords)
            }
          >
            {CustomerprojectRecords.map((record) => (
              <Option key={record.projectID} value={record.projectID}>
                {`${record.projectID} - ${record.description}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="Appointment.DefaultProjectTask" />}
          name="defaultProjectTask"
          rules={[
            {
              required: isrequired,
              message: (
                <FormattedMessage id="Appointment.PleaseselectdefaultProjectTask" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectdefaultProjectTask" />
            }
            allowClear
            disabled={defaultProjDisable}
            filterOption={commonFilterOption}
            onChange={(value, option) =>
              handleDefaultProjectSelected(
                value,
                option,
                customerDefaultProject
              )
            }
          >
            {customerDefaultProject.map((record) => (
              <Option key={record.projectTaskID} value={record.projectTaskID}>
                {`${record.projectTaskID} - ${record.description}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="Summary.ProblemDescription" />}
          name="problemDescription"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectProblemDescription" />
              ),
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <h4>
          {" "}
          <FormattedMessage id="Appointment.Details" />
        </h4>
        <Form.Item
          label={<FormattedMessage id="Appointment.lineType" />}
          name="lineType"
        >
          <Input
            value={"Service"}
            placeholder={intl.formatMessage({
              id: "Appointment.Service",
            })}
            disabled={true}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="Appointment.Inventory" />}
          name="inventoryID"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="Appointment.PleaseselectInventory" />
              ),
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={
              <FormattedMessage id="Appointment.PleaseselectInventory" />
            }
            allowClear
            disabled={locationDisable}
            filterOption={commonFilterOption}
            onChange={(value, option) =>
              handleInventorySelected(value, option, serviceItemsList)
            }
          >
            {serviceItemsList
              .filter(
                (record) => !excludeServiceItems.includes(record.inventoryID)
              )
              .map((record) => (
                <Option key={record.inventoryID} value={record.inventoryID}>
                  {`${record.inventoryID} - ${record.description}`}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="SaveAppointment">
          <div
            className="text-right"
            style={{
              marginTop: 24,
              marginRight: 50,
            }}
          >
            <Button type="primary" onClick={() => handelCreateApp()}>
              <FormattedMessage id="Appointment.CreateAppointment" />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
export default CreateAppointment;
