import React, { useState } from "react";
import { Button, message, Steps, theme } from "antd";
import CustomerDetails from "./CustomerDetails";
import CustomerApprovalForm from "./CustomerApprovalForm";
import CustomerSignatureDelivery from "./CustomerSignatureDelivery";
const { Step } = Steps;

function CustomerApproval() {
  const steps = [
    {
      title: "Details",
      content: <CustomerDetails />,
    },
    {
      title: "Cust Approval",
      content: <CustomerApprovalForm />,
    },
    {
      title: "Signature and Delivery",
      content: <CustomerSignatureDelivery />,
    },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Steps current={current} size="small">
        {steps.map((item, index) => (
          <Step
            key={item.title}
            title={item.title}
            description={item.description}
          />
        ))}
      </Steps>
      <div>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
        className="text-center"
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
      </div>
    </>
  );
}
export default CustomerApproval;
