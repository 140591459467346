import { createSlice } from "@reduxjs/toolkit";

export const ResultPopupSlice = createSlice({
  name: "ResultPopup",
  initialState: {
    isModalOpen: false,
    status: [],
    title: [],
    subTitle: [],
    showBackButton: false,
    showReloadButton: false,
    backButtonRedirectPath: "",
    backButtonName: "",
    callBack: null,
  },

  reducers: {
    showOrHideResultPopup(state, action) {
      state.isModalOpen = action.payload;
      state.status = "";
      state.title = "";
      state.subTitle = "";
      state.showBackButton = false;
      state.showReloadButton = false;
      state.backButtonRedirectPath = "";
      state.backButtonName = "";
      state.callBack = null;
    },

    showResultPopupMessage: (state, action) => {
      state.isModalOpen = true;
      state.status = action.payload.status;
      state.title = action.payload.title;
      state.subTitle = action.payload.subTitle;
      state.showBackButton = action.payload.showBackButton ?? false;
      state.backButtonRedirectPath = action.payload.backButtonRedirectPath;
      state.showReloadButton = action.payload.showReloadButton ?? false;
      state.backButtonName = action.payload.backButtonName;
      state.callBack = action.payload.callBack;
    },
  },
});

export const { showResultPopupMessage, showOrHideResultPopup } =
  ResultPopupSlice.actions;

export default ResultPopupSlice.reducer;
