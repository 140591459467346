import React, { useState, useEffect } from "react";
import { Modal, message, Form, Input } from "antd";
import SketchCanvas from "../../CommonComponents/Drawing/SketchCanvas";
import { updateSignatureAPI } from "./CaptureSignatureAPI";
import { resetState } from "./CaptureSignatureSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
function CaptureSignature(props) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signImage, setSignImage] = useState();
  const [signAvailable, setSignAvailable] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const [nameValue, setNameValue] = useState("");
  const { blankBase64 } = useSelector((state) => state.Signature);
  const handleCancel = () => {
    setIsModalOpen(false);
    props.setSignatureCapture(false);
    form.resetFields();
    setBackgroundImage();
  };

  useEffect(() => {
    if (signImage?.data || signImage?.data == "") {
      if (signImage?.data == "") {
        setBackgroundImage();
      } else {
        setBackgroundImage(signImage?.data);
      }
    }
  }, [signImage]);

  useEffect(() => {
    setIsModalOpen(props.signatureCapture);
  }, [props.signatureCapture]);
  useEffect(() => {
    const fullName = form.getFieldValue("Nameperson");
    if (signImage && signImage.data) {
      if (signImage?.data != blankBase64 && signImage?.data != "") {
        if (fullName == undefined || fullName == "") {
          form.setFields([
            {
              name: "Nameperson",
              errors: [
                <FormattedMessage id="CaptureSignature.Nameisrequired" />,
              ],
            },
          ]);
        } else {
          var newText = signImage.data.replace("data:image/png;base64,", "");
          var request = {
            appointmentNbr: props.Appdetails.appointmentNbr,
            serviceOrderType: props.Appdetails.serviceOrderType,
            signatureFileContent: newText,
            NoteID: props.Appdetails.appointmentNoteId,
            userName: props.Appdetails.userName,
            fullName: fullName,
          };
          dispatch(updateSignatureAPI(request));
          setIsModalOpen(false);
          form.resetFields();
          setBackgroundImage();
        }
      } else {
        message.warning(
          intl.formatMessage({ id: "CaptureSignature.SignatureCannotBlank" })
        );
      }
      setSignImage({});
    }
  }, [signImage, props.Appdetails, dispatch]);
  return (
    <>
      <Modal
        title={<FormattedMessage id="AppointmentStatus.CaptureSignature" />}
        open={isModalOpen}
        centered={true}
        onCancel={handleCancel}
        footer={[]}
        // style={{
        //   top: 30,
        // }}
        width={800}
        maskClosable={false}
      >
        <Form
          form={form}
          name="basic"
          layout="horizontal"
          labelWrap
          colon={false}
          labelCol={{
            span: 6,
            xs: 8,
            md: 8,
            lg: 6,
            xl: 6,
          }}
          wrapperCol={{
            span: 18,
            xs: 16,
            md: 18,
            lg: 18,
            xl: 17,
          }}
          initialValues={{
            remember: false,
          }}
        >
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={<FormattedMessage id="CaptureSignature.Nameperson" />}
            name="Nameperson"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="CaptureSignature.PleaseselectNameperson" />
                ),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: "CaptureSignature.Nameperson",
              })}
              // onChange={(e) => setNameValue(e.target.value)}
            />
          </Form.Item>
        </Form>
        <SketchCanvas
          key={props.signatureCapture ? Date.now() : "default"}
          id="CustomerSignature"
          height="340px"
          setImage={setSignImage}
          setSignAvailable={setSignAvailable}
          ifCanceled={props?.captureSignatureData}
          backgroundImage={backgroundImage}
        />
      </Modal>
    </>
  );
}

export default CaptureSignature;
