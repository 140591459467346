import { createSlice } from "@reduxjs/toolkit";
export const TasksSlice = createSlice({
  name: "Tasks",
  initialState: {
    equipmentList: [],
    tasksRecords: [],
    serviceTemplate: {},
    hideUpdateButtonStatus: true,
    equipmentGuid: {},
  },
  reducers: {
    getEquipmentList: (state, action) => {
      state.equipmentList = action.payload;
    },
    getTasksRecords: (state, action) => {
      state.tasksRecords = action.payload;
    },
    getServiceTemplateRecord: (state, action) => {
      state.serviceTemplate = action.payload;
    },
    showUpdateButton(state) {
      state.hideUpdateButtonStatus = false;
    },
    hideUpdateButton(state) {
      state.hideUpdateButtonStatus = true;
    },
    getEquipmentGuid: (state, action) => {
      state.equipmentGuid = action.payload;
    },
  },
});
export const {
  getEquipmentList,
  getTasksRecords,
  getServiceTemplateRecord,
  showUpdateButton,
  hideUpdateButton,
  getEquipmentGuid,
} = TasksSlice.actions;
export default TasksSlice.reducer;
