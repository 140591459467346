import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import {
  VisitHistoryListSuccess,
  VisitHistoryDetails,
  getAppointmentDetails,
  showLocalLoader,
  hideLocalLoader,
  resetState,
  resetOnSearch,
  updatePgNumber,
} from "./VisitHistorySlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getVisitHistoryApi = (payload) => (dispatch) => {
  try {
    dispatch(showLocalLoader());
    return postRequest(`api/Appointment/PastVisits`, payload)
      .then((res) => {
        dispatch(VisitHistoryListSuccess(res.data));
        dispatch(hideLocalLoader());
        return res.data;
      })
      .catch((e) => {
        dispatch(hideLocalLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Visits.Unablefetchvisitlist",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLocalLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Visits.Unablefetchvisitlist",
        type: "error",
      })
    );
    return false;
  }
};

export const getVisitHistoryDetailsApi = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return getRequest(
      `api/Appointment/VisitDetails?appointmentNbr=${payload.appointmentNbr}&noteID=${payload.noteId}&locale=0`
    )
      .then((res) => {
        dispatch(VisitHistoryDetails(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Visits.Unablefetchdata",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Visits.Unablefetchdata",
        type: "error",
      })
    );
  }
};

export const forVisitDetailsApi = (visitNoteId) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Appointment/Details?appointmentNoteId=${visitNoteId}`)
      .then((res) => {
        var obj = {
          noteId: visitNoteId,
          appointmentNbr: res.data[0].appointmentNbr,
        };
        dispatch(getVisitHistoryDetailsApi(obj));
      })
      .catch((e) => {
        dispatch(hideLoader());
      });
  } catch (e) {
    dispatch(hideLoader());
  }
};

export const getAppointmentDetailsApi = (appointmentNoteId) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Appointment/Details?appointmentNoteId=${appointmentNoteId}`)
      .then((res) => {
        dispatch(getAppointmentDetails(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
      });
  } catch (e) {
    dispatch(hideLoader());
  }
};

export const clearVisitsStateApi = () => (dispatch) => {
  dispatch(resetState());
};

export const clearVisitsOnSearchApi = () => (dispatch) => {
  dispatch(resetOnSearch());
};

export const setPageNo = (pgNumber) => (dispatch) =>
  Promise.all([dispatch(updatePgNumber(pgNumber))]);
