import React, { useState, useEffect } from "react";
import { Flex, Modal, Row, Col, Button } from "antd";
import CustomerApproval from "./CustomerApproval";
function ReviewAndComplete(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    props.setReview(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    props.setReview(false);
  };
  useEffect(() => {
    setIsModalOpen(props.review);
  }, [props.review]);
  return (
    <>
      <Modal
        title="Review and Complete"
        open={isModalOpen}
        onOk={handleOk}
        width={900}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ display: showResults ? "none" : "block" }}>
          <div className="responsive-layout-custom">
            <Row className="mb-1 border-bottom">
              <Col span={8}>
                MSPL-8 <br />
                Mini bi-bloc- MSPL
              </Col>
              <Col span={8}>
                <Flex gap="middle" vertical>
                  <div>
                    <i className="simple-icon-check"></i>&nbsp;9/9
                  </div>
                  <div>
                    <i className="simple-icon-social-dropbox"></i>&nbsp;0
                  </div>
                  <div>
                    <i className="iconsminds-snow"></i>&nbsp;0
                  </div>
                </Flex>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={12}>
                    <h6 className="mb-1">Worked</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-small font-weight-semibold text-danger">
                        0.00
                      </span>
                    </Flex>
                  </Col>
                  <Col span={12}>
                    <h6 className="mb-1">Charged</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">
                        0.00 Regular
                      </span>
                      <span className="text-muted text-small">0.00 Over</span>
                      <span className="text-muted text-small">0.00 Double</span>
                      <span className="text-muted text-small font-weight-semibold">
                        0.00 hrs
                      </span>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-1 border-bottom">
              <Col span={8}>
                MSPL-8 <br />
                Mini bi-bloc- MSPL
              </Col>
              <Col span={8}>
                <Flex gap="middle" vertical>
                  <div>
                    <i className="simple-icon-check"></i>&nbsp;9/9
                  </div>
                  <div>
                    <i className="simple-icon-social-dropbox"></i>&nbsp;0
                  </div>
                  <div>
                    <i className="iconsminds-snow"></i>&nbsp;0
                  </div>
                </Flex>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={12}>
                    <h6 className="mb-1">Worked</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-small font-weight-semibold text-danger">
                        0.00
                      </span>
                    </Flex>
                  </Col>
                  <Col span={12}>
                    <h6 className="mb-1">Charged</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">
                        0.00 Regular
                      </span>
                      <span className="text-muted text-small">0.00 Over</span>
                      <span className="text-muted text-small">0.00 Double</span>
                      <span className="text-muted text-small font-weight-semibold">
                        0.00 hrs
                      </span>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-1 border-bottom">
              <Col span={8}>
                MSPL-8 <br />
                Mini bi-bloc- MSPL
              </Col>
              <Col span={8}>
                <Flex gap="middle" vertical>
                  <div>
                    <i className="simple-icon-check"></i>&nbsp;9/9
                  </div>
                  <div>
                    <i className="simple-icon-social-dropbox"></i>&nbsp;0
                  </div>
                  <div>
                    <i className="iconsminds-snow"></i>&nbsp;0
                  </div>
                </Flex>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={12}>
                    <h6 className="mb-1">Worked</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-muted text-small">0.00</span>
                      <span className="text-small font-weight-semibold text-danger">
                        0.00
                      </span>
                    </Flex>
                  </Col>
                  <Col span={12}>
                    <h6 className="mb-1">Charged</h6>
                    <Flex gap="middle" vertical className="worked-hours">
                      <span className="text-muted text-small">
                        0.00 Regular
                      </span>
                      <span className="text-muted text-small">0.00 Over</span>
                      <span className="text-muted text-small">0.00 Double</span>
                      <span className="text-muted text-small font-weight-semibold">
                        0.00 hrs
                      </span>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="text-center mt-2">
            <Button
              key="submit"
              type="primary"
              onClick={() => setShowResults(true)}
            >
              Customer appr
            </Button>
          </div>
        </div>

        {showResults ? (
          <>
            <CustomerApproval />
          </>
        ) : null}
      </Modal>
    </>
  );
}
export default ReviewAndComplete;
