export const getActualHours = (hours) => {
  if (hours?.length >= 2) {
    let lastTwoDigits = hours.substring(hours.length - 2);
    let remainingString = hours.substring(0, hours.length - 2);
    return `${remainingString} hr ${lastTwoDigits} min`;
  } else {
    return `${hours || 0} hr 00 min`;
  }
};

export const getActualHours2 = (hours) => {
  if (hours?.trim().length >= 2) {
    let lastTwoDigits = hours.substring(hours.length - 2);
    let remainingString = hours.substring(0, hours.length - 2);
    return `${remainingString}.${lastTwoDigits}`;
  } else {
    return `${hours || 0}.00`;
  }
};
