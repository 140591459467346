import { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import ProtectedRoutes from "routes/ProtectedRoutes";
import ResultPopup from "./features/CommonComponents/ResultPopup/ResultPopup";
import NofificationMessages from "./features/CommonComponents/NofificationMessages/NofificationMessages";
const LoginPage = lazy(() => import("features/OnBoarding/LoginPage"));
const Maintenance = lazy(() => import("features/OnBoarding/Maintanance"));

function App() {
  const dispatch = useDispatch();
  //Getting isAuthenticated store value from Authentication slice.
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const isAuthenticatedState = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticatedState) {
      var access_token = localStorage.getItem("access_token");
      if (access_token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(isAuthenticatedState);
    }
  }, [isAuthenticatedState, dispatch]);

  return (
    <>
      {isAuthenticated != null && (
        <Router>
          <ResultPopup />
          <NofificationMessages />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
                <LoginPage />
              </PublicRoute>
              <PublicRoute path="/maintenancemode">
                <Maintenance />
              </PublicRoute>
              <PrivateRoute path="/dashboard" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/appointment"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              {/* <PrivateRoute path="/summary" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute> */}
              {/* <PrivateRoute path="/equipment" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/equipment_details"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/labour" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/labourdetails"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/parts" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/partdetails"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/refrigerant"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/refrigerantdetails"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/tasks" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/othercost" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/othercostdetails"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/workdone" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/additionalwork"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/preventative_maintenance"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/preapproved_quote"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/servicecalltime"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/order" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute> */}
              <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </Router>
      )}
    </>
  );
}

// Private route restrict to access public pages after login.
function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          rest.path === "/" ? (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// Public route restrict to access authenticated pages before login.
function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
