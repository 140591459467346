import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  summaryRecord: null,
  loader: false,
  moreNotesEllipsis: true,
  moreDispatchEllipsis: true,
};

const SummarySlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    summaryRecordSuccess(state, action) {
      state.summaryRecord = action.payload;
    },
    ClearSummary(state, action) {
      state.summaryRecord = null;
    },
    showMoreEllipsis(state) {
      state.moreNotesEllipsis = true;
    },
    hideMoreEllipsis(state) {
      state.moreNotesEllipsis = false;
    },
    showMoreDispatchEllipsis(state) {
      state.moreDispatchEllipsis = true;
    },
    hideMoreDispatchEllipsis(state) {
      state.moreDispatchEllipsis = false;
    },
  },
});

export const {
  summaryRecordSuccess,
  ClearSummary,
  showMoreEllipsis,
  hideMoreEllipsis,
  showMoreDispatchEllipsis,
  hideMoreDispatchEllipsis,
} = SummarySlice.actions;
export default SummarySlice.reducer;
