import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderVirtualList from "./RenderVitualList";
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Dropdown,
  Steps,
  Spin,
} from "antd";
import { updateAppointmentDetails } from "../Actions/ActionsAPI";
import { useHistory } from "react-router-dom";
import CreateAppointment from "../AddAppointment/CreateAppointment";
import Form from "antd/es/form/Form";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import {
  getAppointListAPI,
  resetAppointmentstate,
  setPageNo,
  setSelectedAppointment,
} from "./AppointmentsAPI";
const { TextArea } = Input;
const { Step } = Steps;

function Appointments() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isOnSearchMode, setSearchMode] = useState(false);
  const [createAppFormReset, setCreateAppFormReset] = useState(false);
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const [pickerStartDate, setPickerStartDate] = useState();
  const [pickerEndDate, setPickerEndDate] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { pageNo, redStartDate, redEndDate } = useSelector(
    (state) => state.AppointmentsSlice
  );
  const history = useHistory();

  const todayStartDate = dayjs().startOf("day");
  const todayEndDate = dayjs().endOf("day");
  const pastStartDate = dayjs()
    .add(-31, "d")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0);
  const pastEndDate = dayjs()
    .add(-1, "d")
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59);
  const futureStartDate = dayjs()
    .add(+1, "d")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0);
  const futureEndDate = dayjs()
    .add(+31, "d")
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59);

  const showModal = () => {
    setCreateAppFormReset(true);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCreateAppFormReset(true);
  };

  const handleMenuClick = (e) => {
    if (e.key === "31") {
      setOpen(false);
    }
  };
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  useEffect(() => {
    const selectedAppointmentInfo = localStorage.getItem("selectedAppointment");
    if (
      selectedAppointmentInfo == null ||
      selectedAppointmentInfo == "null" ||
      selectedAppointmentInfo == "[object Object]"
    ) {
      fetchList(1, todayStartDate, todayEndDate);
      setStartDate(todayStartDate);
      setEndDate(todayEndDate);
      setPickerStartDate(todayStartDate);
      setPickerEndDate(todayEndDate);
    } else {
      const info = JSON.parse(selectedAppointmentInfo);
      getSelectedAppointmentInfo(info);
      setSearchInput(info.appointmentNbr);
    }
  }, []);

  const getSelectedAppointmentInfo = (selectedAppointmentInfo) => {
    dispatch(updateAppointmentDetails(selectedAppointmentInfo));
  };

  useEffect(() => {
    if (redStartDate != null && redEndDate != null) {
      setEndDate(redEndDate);
      setStartDate(redStartDate);
      setPickerEndDate(redEndDate);
      setPickerStartDate(redStartDate);
      //history.push(`/dashboard`);
    }
  }, [redStartDate, redEndDate]);

  const onSubmit = () => {
    setStartDate(pickerStartDate);
    setEndDate(pickerEndDate);
    setSearchMode(true);
    setPageNo(1);
    fetchList(1);
    history.push(`/dashboard`);
  };

  const fetchList = (pageNumber, fromDate, ToDate, searchTerm) => {
    localStorage.setItem("selectedAppointment", null);
    dispatch(resetAppointmentstate());
    dispatch(setSelectedAppointment(""));
    //history.push(`/dashboard`);
    appendData(pageNumber, fromDate, ToDate, searchTerm);
  };

  const appendData = (pageNumber, fromDate, toDate, keyword) => {
    var sDate = fromDate ? fromDate : pickerStartDate;
    var eDate = toDate ? toDate : pickerEndDate;
    var empId = localStorage.getItem("employeeId");
    if (empId)
      var obj = {
        staffMemberId: empId,
        pageNumber: pageNumber ? pageNumber : pageNo,
        pageSize: pageSize,
        searchTerm: keyword != null ? "" : searchInput,
        startDate: sDate?.format("YYYY-MM-DDTHH:mm:ssZ"),
        endDate: eDate?.format("YYYY-MM-DDTHH:mm:ssZ"),
      };
    dispatch(getAppointListAPI(obj, false)).then((res) => {
      if (res && res.length > 0) {
        dispatch(setPageNo((pageNumber ? pageNumber : pageNo) + 1));
      }
    });
  };

  const onSearchTextChange = (e) => {
    setSearchInput(e.target.value);
  };

  const fetchPastData = (e) => {
    setStartDate(pastStartDate);
    setEndDate(pastEndDate);
    setSearchMode(true);
    setPickerStartDate(pastStartDate);
    setPickerEndDate(pastEndDate);
    setSearchInput("");
    fetchList(1, pastStartDate, pastEndDate, "");
    history.push(`/dashboard`);
  };

  const fetchTodayData = (e) => {
    setPickerStartDate(todayStartDate);
    setPickerEndDate(todayEndDate);
    setStartDate(todayStartDate);
    setEndDate(todayEndDate);
    setSearchInput("");
    fetchList(1, todayStartDate, todayEndDate, "");
    history.push(`/dashboard`);
  };

  const fetchFutureData = (e) => {
    setSearchMode(true);
    setStartDate(futureStartDate);
    setEndDate(futureEndDate);
    setPickerStartDate(futureStartDate);
    setPickerEndDate(futureEndDate);
    setSearchInput("");
    fetchList(1, futureStartDate, futureEndDate, "");
    history.push(`/dashboard`);
  };

  const showAll = () => {
    setSearchInput("");
    setPageNo(1);
    setPickerStartDate(todayStartDate);
    setPickerEndDate(todayEndDate);
    setStartDate(todayStartDate);
    setEndDate(todayEndDate);
    setSearchMode(false);
    fetchList(1, todayStartDate, todayEndDate, "");
    history.push(`/dashboard`);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setPickerStartDate(
        dates[0].set("hour", 0).set("minute", 0).set("second", 0)
      );
      setPickerEndDate(
        dates[1].set("hour", 23).set("minute", 59).set("second", 59)
      );
    } else {
      setPickerStartDate();
      setPickerEndDate();
    }
  };

  const filterItems = [
    {
      label: (
        <RangePicker
          inputReadOnly={true}
          presets={[
            {
              label: (
                <span aria-label="Last 30 Days">
                  <FormattedMessage id="Appointment.Last30Days" />
                </span>
              ),
              value: () => [pastStartDate, pastEndDate], // 5.8.0+ support function
            },
            {
              label: (
                <span aria-label="Current Time to End of Day">
                  <FormattedMessage id="Appointment.Today" />
                </span>
              ),
              value: () => [todayStartDate, todayEndDate],
            },
            {
              label: (
                <span aria-label="Next 30 Days">
                  <FormattedMessage id="Appointment.Next30Days" />
                </span>
              ),
              value: () => [futureStartDate, futureEndDate], // 5.8.0+ support function
            },
          ]}
          allowClear={true}
          picker="date"
          format="YYYY/MM/DD"
          onChange={onRangeChange}
          value={[pickerStartDate, pickerEndDate]}
        />
      ),
      key: "0",
    },
    {
      label: (
        <Input
          placeholder={intl.formatMessage({
            id: "Appointment.InputSearchText",
          })}
          value={searchInput}
          onChange={onSearchTextChange}
          allowClear={true}
        />
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <div className="text-right">
            <Button type="primary" size="small" onClick={onSubmit}>
              <FormattedMessage id="Common.Search" />
            </Button>
          </div>
        </>
      ),
      key: "31",
    },
  ];

  return (
    <>
      <div className="post-icon">
        <Row style={{ paddingLeft: "10px", height: "100%" }} align="middle">
          <Col span={12} className="">
            <Dropdown
              menu={{
                items: filterItems,
                onClick: handleMenuClick,
              }}
              trigger={["click"]}
              placement="bottomRight"
              arrow
              onOpenChange={handleOpenChange}
              open={open}
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="filter-icon-position"
              >
                <i className="iconsminds-filter-2"></i>
              </a>
            </Dropdown>
          </Col>
          <Col
            span={12}
            className="text-right"
            style={{ paddingRight: "10px" }}
          >
            {startDate?.diff(todayStartDate, "day") === 0 &&
            endDate?.diff(todayEndDate, "day") === 0 &&
            searchInput === "" ? null : (
              <Button
                type="primary"
                size="small"
                style={{
                  background: "#0958d9",
                  borderRadius: "14px",
                  padding: "0 10px",
                }}
                onClick={() => showAll()}
              >
                <FormattedMessage id="Appointment.FilterApplied" />
                &nbsp;
                <CloseOutlined />
              </Button>
            )}
          </Col>
          {/* <Col
            span={5}
            className="text-left"
            style={{ marginTop: "3px", marginBottom: "3px" }}
          >
            <Button
              type="link"
              style={{
                padding: "4px 5px",
              }}
              onClick={() => showAll()}
              disabled={
                startDate?.diff(todayStartDate, "day") == 0 &&
                endDate?.diff(todayEndDate, "day") == 0 &&
                searchInput == ""
              }
            >
              <FormattedMessage id="Appointment.Clear" />
            </Button>
          </Col> */}
        </Row>
      </div>
      <div className="h-100 height94">
        <RenderVirtualList
          fetchList={appendData}
          isOnSearchMode={isOnSearchMode}
        />

        <Modal
          title={<FormattedMessage id="Appointment.AddAppointment" />}
          centered={true}
          open={isModalOpen}
          maskClosable={false}
          onOk={handleOk}
          width={800}
          onCancel={handleCancel}
          footer={null}
        >
          <div id="area">
            <CreateAppointment
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              createAppFormReset={createAppFormReset}
            />
          </div>
        </Modal>
      </div>
      <div className="appoint-btn">
        <Row>
          <Col flex="auto" className="text-center">
            <Button
              type="default"
              size="small"
              className={
                startDate?.diff(pastStartDate, "day") == 0 &&
                endDate?.diff(pastEndDate, "day") == 0
                  ? "appoint-btn-active"
                  : ""
              }
              onClick={fetchPastData}
            >
              <FormattedMessage id="Appointment.Past" />
            </Button>
            <Button
              type="default"
              size="small"
              className={
                startDate?.diff(todayStartDate, "day") == 0 &&
                endDate?.diff(todayEndDate, "day") == 0
                  ? "appoint-btn-active"
                  : ""
              }
              onClick={fetchTodayData}
            >
              <FormattedMessage id="Appointment.Today" />
            </Button>
            <Button
              type="default"
              size="small"
              className={
                startDate?.diff(futureStartDate, "day") == 0 &&
                endDate?.diff(futureEndDate, "day") == 0
                  ? "appoint-btn-active"
                  : ""
              }
              onClick={fetchFutureData}
            >
              <FormattedMessage id="Appointment.Future" />
            </Button>
          </Col>
          <Col flex="20px">
            <Button
              type="link"
              size="small"
              onClick={showModal}
              style={{ top: "-4px" }}
            >
              <i className="iconsminds-add"></i>
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Appointments;
