import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    tokenDetails: {},
    isAuthenticated: false,
    loader: false,
  },
  reducers: {
    logIn: (state, action) => {
      state.tokenDetails = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { logIn, showLoader, hideLoader } = authenticationSlice.actions;

export default authenticationSlice.reducer;
