import { Row, Col, Typography, Input } from "antd";
const { TextArea } = Input;
const { Text } = Typography;
function CustomerDetails() {
  return (
    <>
      <div className="separator mt-2 mb-2"></div>
      <div className="equipment" style={{ paddingTop: "0" }}>
        <Row gutter={16} className="mb-1">
          <Col span={24} md={12} lg={12}>
            <Row gutter={10} className="mb-1">
              <Col span={8}>
                <label className="theme-color-new font-weight-semibold">
                  Call
                </label>
              </Col>
              <Col span={16}>
                <Text>27322</Text>
              </Col>
            </Row>
            <Row gutter={10} className="mb-1">
              <Col span={8}>
                <label className="theme-color-new font-weight-semibold">
                  Technician
                </label>
              </Col>
              <Col span={16}>
                <Text>Apple test user</Text>
              </Col>
            </Row>
            <Row gutter={10} className="mb-1">
              <Col span={8}>
                <label className="theme-color-new font-weight-semibold">
                  Service Date
                </label>
              </Col>
              <Col span={16}>
                <Text>02/05/2020</Text>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={12} lg={12}>
            <Row gutter={10} className="mb-1">
              <Col span={8}>
                <label className="theme-color-new font-weight-semibold">
                  Site
                </label>
              </Col>
              <Col span={16}>
                <Text>K Mart 9705</Text>
              </Col>
            </Row>
            <Row gutter={10} className="mb-1">
              <Col span={8}>
                <label className="theme-color-new font-weight-semibold">
                  Address
                </label>
              </Col>
              <Col span={16}>
                <Text>6600 N RIDGE RD MADISON, OH 44057</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16} className="mb-1">
          <Col span={24} md={4} xs="4">
            <label className="theme-color-new font-weight-semibold">
              Problem
            </label>
          </Col>
          <Col span={24} md={20} xs="20">
            <TextArea rows={3} value={"FUITE AU NATUREL"} />
          </Col>
        </Row>
        <Row gutter={16} className="mb-1">
          <Col span={24} sm={4}>
            <label className="theme-color-new font-weight-semibold">
              Labor
            </label>
          </Col>
          <Col span={24} sm={20}>
            <Row>
              <Col span={12}>
                <p>
                  <b>Total:4.00 / Billable:4.00</b>
                </p>
              </Col>
              <Col span={12} className="text-right">
                <p>
                  <b>R:4.00 / O:4.00 / D:0.00</b>
                </p>
              </Col>
            </Row>
            <p className="text-small text-muted mb-1">
              Disclamer: Allocation of changes is subject to change based on
              management review based on the actual contract coverage
            </p>
          </Col>
        </Row>
        <Row gutter={16} className="mb-1">
          <Col span={24} sm={4}>
            <label className="theme-color-new font-weight-semibold">
              Work Done
            </label>
          </Col>
          <Col span={24} sm={20}>
            <TextArea
              rows={3}
              value={
                "Started scheduled planned maintenanace will return to complete PM."
              }
            />
            <span className="text-small text-muted">
              * Equipement related work done details are available on the next
              page
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default CustomerDetails;
