import { createSlice } from "@reduxjs/toolkit";
export const AttachmentsSlice = createSlice({
  name: "Attachments",
  initialState: {
    attachments: [],
  },
  reducers: {
    getAttachments: (state, action) => {
      state.attachments = action.payload;
    },
  },
});
export const { getAttachments } = AttachmentsSlice.actions;
export default AttachmentsSlice.reducer;
