import { getRequest, postRequest, deleteRequest } from "app/axiosClient";
import { message } from "antd";
import {
  getCustomerListRecords,
  getCustomerLocationRecords,
  getProjectRecords,
  getDefaultProjectRecords,
  getServiceItemRecords,
} from "../AddAppointment/CreateAppointmentSlice";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import {
  getAppointListAPI,
  setPageNo,
  setSelectedAppointment,
  resetAppointmentstate,
  setRange,
} from "../AppointmentList/AppointmentsAPI";
import dayjs from "dayjs";
import { showNotification } from "../../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getAllCustomerListApi = () => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetCustomersList`)
      .then((res) => {
        dispatch(getCustomerListRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.RequestError",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.NoComments",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getAllCustomerLocationListApi = (value) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetCustomersLocationList?CustomerID=${value}`)
      .then((res) => {
        dispatch(getCustomerLocationRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.RequestError",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.NoComments",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getCustomerProjectsListApi = (value) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetProjectsList?CustomerID=${value}`)
      .then((res) => {
        dispatch(getProjectRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.RequestError",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.NoComments",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getCustomerDefaultProjectRecords = (value) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetProjectTaskList?projectID=${value}`)
      .then((res) => {
        dispatch(getDefaultProjectRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.RequestError",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.NoComments",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getNonStockItemRecordsApi = (value) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetServiceItemsList?ItemType=${value}`)
      .then((res) => {
        dispatch(getServiceItemRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.RequestError",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.NoComments",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const addNewAppointmentAPI = (payload, refreshURL) => (dispatch) => {
  try {
    const data = payload;
    dispatch(showLoader());
    postRequest(`api/Appointment/AddAppointment`, data)
      .then((res) => {
        var appointmentid = res.data.appointmentId;
        var appointmentNbr = res.data.appointmentNbr;
        dispatch(resetAppointmentstate());
        var empId = localStorage.getItem("employeeId");
        if (empId)
          var obj = {
            staffMemberId: empId,
            pageNumber: 1,
            pageSize: 10,
            searchTerm: "",
            startDate: dayjs().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
            endDate: dayjs().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
          };
        dispatch(
          setRange({
            startDate: dayjs().startOf("day"),
            endDate: dayjs().endOf("day"),
          })
        );
        dispatch(getAppointListAPI(obj, true)).then((result) => {
          if (result && result.length > 0) {
            if (
              result.find((element) => element.noteID === appointmentid) != null
            ) {
              const appointmentDetails = {
                appointmentNoteId: appointmentid,
                appointmentNbr: appointmentNbr,
              };
              JSON.stringify(appointmentDetails);
              localStorage.setItem(
                "selectedAppointment",
                JSON.stringify(appointmentDetails)
              );
              dispatch(setSelectedAppointment(appointmentid));
              refreshURL(appointmentid);
            } else {
              refreshURL(null);
              dispatch(setSelectedAppointment(null));
            }
            dispatch(setPageNo(2));
          }
        });

        const successMessage = `Successfully added new Appointment App no: ${appointmentNbr}`;
        message.success({ content: successMessage, duration: 5 });
        dispatch(hideLoader());
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "CreateAppointment.UnableAddNewAppointment",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "CreateAppointment.UnableAddNewAppointment",
        type: "error",
      })
    );
  }
};
