import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PullToRefresh from "react-simple-pull-to-refresh";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "app/store";
import "./index.css";
import "react-quill/dist/quill.snow.css";
import { IntlProvider } from "react-intl";
import Wrapper from "./components/Wrapper";

const handleRefresh = async () => {
  // Reload the entire application
  window.location.reload();
  // Return a resolved promise to satisfy the pull-to-refresh library
  return Promise.resolve();
};

ReactDOM.render(
  <React.StrictMode>
    {/* Redux Provider is included to access the store values from anywhere inside the child components. */}
    <Wrapper>
      <Provider store={store()}>
        <PullToRefresh onRefresh={handleRefresh}>
          <App />
        </PullToRefresh>
      </Provider>
    </Wrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
