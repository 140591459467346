import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOnHoldSuccess: false,
  isOnStartSuccess: false,
  isOnPauseSuccess: false,
  isOnResumeSuccess: false,
  isOnCompleteSuccess: false,
  previewReportData: null,
  previewReportWithTaskData: null,
  loader: false,
  isCancelSucess: false,
};

const ActionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    onHoldSuccess(state, action) {
      state.isOnHoldSuccess = action.payload;
    },
    onStartSuccess(state, action) {
      state.isOnStartSuccess = action.payload;
    },
    onPauseSuccess(state, action) {
      state.isOnPauseSuccess = action.payload;
    },
    onResumeSuccess(state, action) {
      state.isOnResumeSuccess = action.payload;
    },
    onCompleteSuccess(state, action) {
      state.isOnCompleteSuccess = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
    onPreviewReportSuccess(state, action) {
      state.previewReportData = action.payload;
    },
    onPreviewReportWithTaskSuccess(state, action) {
      state.previewReportWithTaskData = action.payload;
    },
    resetState(state) {
      state.previewReportData = null;
      state.previewReportWithTaskData = null;
    },
    onCancelSuccess(state, action) {
      state.isCancelSucess = action.payload;
    },
  },
});

export const {
  onHoldSuccess,
  onStartSuccess,
  onPauseSuccess,
  onResumeSuccess,
  onCompleteSuccess,
  onPreviewReportSuccess,
  onPreviewReportWithTaskSuccess,
  resetState,
  onCancelSuccess,
} = ActionsSlice.actions;
export default ActionsSlice.reducer;
