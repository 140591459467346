import { getRequest, postRequest } from "app/axiosClient";
import { showResultPopupMessage } from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import { updateAppointmentSuccess } from "../AppointmentList/AppointmentsSlice";
import {
  onPreviewReportSuccess,
  onPreviewReportWithTaskSuccess,
  resetState,
} from "./ActionsSlice";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import {
  showAppLoader,
  hideAppLoader,
} from "../AppointmentList/AppointmentsSlice";
import { message } from "antd";
import { showNotification } from "../../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const updateAppointmentStatus = (payload) => (dispatch) => {
  try {
    var path = `api/Appointment/${payload.action}?appointmentNbr=${payload.appointmentNbr}&serviceOrderType=${payload.serviceOrderType}`;
    dispatch(showAppLoader());
    return postRequest(path)
      .then((res) => {
        dispatch(hideAppLoader());
        dispatch(updateAppointmentDetails(payload));
        return res.data;
      })
      .catch((e) => {
        // message.error(
        //   "La mise à jour du rendez-vous a échoué. Veuillez réessayer!"
        // );
        dispatch(hideAppLoader());
        return false;
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Action.AppointmentUpdateFailed",
        type: "error",
      })
    );
    dispatch(hideAppLoader());
    return false;
  }
};

export const updateAppointmentDetails = (payload) => (dispatch) => {
  try {
    dispatch(showAppLoader());
    return getRequest(
      `api/Appointment/Details?appointmentNbr=${payload.appointmentNbr}&appointmentNoteId=${payload.appointmentNoteId}`
    )
      .then((res) => {
        dispatch(updateAppointmentSuccess(res.data));
        dispatch(hideAppLoader());
        return res.data;
      })
      .catch((e) => {
        dispatch(
          showNotification({
            show: true,
            messageId: "Action.CouldnotFetchPreviewReport",
            type: "error",
          })
        );
        dispatch(hideAppLoader());
        return false;
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Action.CouldnotFetchPreviewReport",
        type: "error",
      })
    );
    dispatch(hideAppLoader());
    return false;
  }
};

export const getPreviewReportAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    const selectedLanguage = localStorage.getItem("selectedlanguage");
    const locale =
      selectedLanguage !== null && selectedLanguage == "fr-FR" ? 1 : 0;
    return getRequest(
      `api/Appointment/PreviewReport?appointmentNbr=${payload.appointmentNbr}&serviceOrderType=${payload.serviceOrderType}&locale=${locale}`
    )
      .then((res) => {
        dispatch(hideLoader());
        dispatch(onPreviewReportSuccess(res.data));
        return res.data;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Action.CouldnotFetchPreviewReport",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Action.CouldnotFetchPreviewReport",
        type: "error",
      })
    );
    return false;
  }
};

export const getPreviewReportWithTasksAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    const selectedLanguage = localStorage.getItem("selectedlanguage");
    const locale =
      selectedLanguage !== null && selectedLanguage == "fr-FR" ? 1 : 0;
    return getRequest(
      `api/Appointment/PreviewReportWithCompletedTask?appointmentNbr=${payload.appointmentNbr}&serviceOrderType=${payload.serviceOrderType}&locale=${locale}`
    )
      .then((res) => {
        dispatch(hideLoader());
        dispatch(onPreviewReportWithTaskSuccess(res.data));
        return res.data;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Action.CouldnotFetchPreviewReport",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Action.CouldnotFetchPreviewReport",
        type: "error",
      })
    );
    return false;
  }
};

export const clearActionsState = () => (dispatch) =>
  Promise.all([dispatch(resetState())]);

export const emailAppointment = (payload) => (dispatch) => {
  try {
    dispatch(showAppLoader());
    const selectedLanguage = localStorage.getItem("selectedlanguage");
    const locale =
      selectedLanguage !== null && selectedLanguage == "fr-FR" ? 1 : 0;
    var data = {
      appointmentNbr: payload.appointmentNbr,
      serviceOrderType: payload.serviceOrderType,
      noteId: payload.noteId,
      userName: payload.userName,
      locale: locale,
      appointmentNoteId: payload.appointmentNoteId,
    };

    var path = `api/Appointment/EmailAppointment?appointmentNbr=${payload.appointmentNbr}&serviceOrderType=${payload.serviceOrderType}&noteId=${payload.noteId}&locale=${locale}&userName=${payload.userName}&reportType=${payload.reportType}`;

    return postRequest(path)
      .then((res) => {
        dispatch(hideAppLoader());
        dispatch(updateAppointmentDetails(data));
        dispatch(
          showNotification({
            show: true,
            messageId: "Action.EmailSuccess",
            type: "success",
          })
        );
        return res.data;
      })
      .catch((e) => {
        // message.error("L'envoi de l'e-mail a échoué. Essayer à nouveau!");
        dispatch(hideAppLoader());
        return false;
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Action.EmailSendingFailed",
        type: "error",
      })
    );
    dispatch(hideAppLoader());
    return false;
  }
};

function retryUpdateDetails(dispatch, payload) {
  dispatch(
    showResultPopupMessage({
      status: "error",
      title:
        "Une erreur s'est produite lors de la récupération des détails. Veuillez réessayer!",
      subTitle: null,
      showBackButton: false,
      backButtonRedirectPath: null,
      backButtonName: null,
      showReloadButton: true,
      callBack: updateAppointmentDetails(payload),
    })
  );
}
