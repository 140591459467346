import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  signatureRecord: [],
  blankBase64: null,
};

const SignatureSlice = createSlice({
  name: "Signature",
  initialState,
  reducers: {
    getSignatureRecords: (state, action) => {
      state.signatureRecord = action.payload;
    },
    resetState(state) {
      state.signatureRecord = [];
    },
    setBlankImage: (state, action) => {
      state.blankBase64 = action.payload;
    },
  },
});
export const { getSignatureRecords, resetState, setBlankImage } =
  SignatureSlice.actions;
export default SignatureSlice.reducer;
