import React, { Suspense, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getVisitHistoryApi,
  getAppointmentDetailsApi,
  clearVisitsStateApi,
  clearVisitsOnSearchApi,
  setPageNo,
} from "./VisitHistoryAPI";
import { Route, Switch, Link, useLocation, useParams } from "react-router-dom";
import VirtualList from "rc-virtual-list";
import {
  Layout,
  Menu,
  Tabs,
  Card,
  Dropdown,
  Tag,
  Row,
  Col,
  Typography,
  Button,
  Space,
  Input,
  List,
  Avatar,
  message,
  Spin,
} from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Filters from "features/CommonComponents/Filters/Filters";
import { appointmentList } from "../Appointments/AppointmentList/AppointmentsSlice";
import EmptyContainer from "features/CommonComponents/Empty/Empty";

const { Title, Text, Paragraph } = Typography;

function VisitHistory() {
  //Find the locationid from the selected appointmentid.
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();
  const { appointmentDetails } = useSelector((state) => state.VisitHistory);
  const { VisitHistory, pageNo, resetState } = useSelector(
    (state) => state.VisitHistory
  );
  // Get Appointment list from redux state/store
  const { appointmentList } = useSelector((state) => state.AppointmentsSlice);
  const { loader } = useSelector((state) => state.VisitHistory);
  // Get appointmentid/Noteid from URL
  const location = useLocation();
  const { pathname } = location;
  const parts = pathname.split("/");
  const appointmentId = parts[2];
  const visitId = parts[4];
  const [selectedCard, setSelectedCard] = useState();
  const [searchinputs, setSearchinputs] = useState();
  const [searchData, setSearchData] = useState({});
  const [clearFilter, setClearFilter] = useState();
  const [filterinfo, setFilterinfo] = useState();
  const [filterData, setFilterData] = useState(null);
  const dispatch = useDispatch();
  const filterConfig = "Visits";

  const appendData = (pageNumber) => {
    if (appointmentDetails.length > 0) {
      const locationid = appointmentDetails[0].location;
      const customerId = appointmentDetails[0].customerID;
      var obj = {
        locationId: filterData ? filterData.locationId : locationid,
        customerId: filterData ? filterData.customerId : customerId,
        pageNumber: pageNumber ? pageNumber : pageNo,
        pageSize: pageSize,
        sotsearch: filterData ? filterData.sotsearch : null,
        empsearch: filterData ? filterData.empsearch : null,
        startDate: filterData ? filterData.startDate : null,
        endDate: filterData ? filterData.endDate : null,
      };
      dispatch(getVisitHistoryApi(obj)).then((res) => {
        if (res && res.length > 0) {
          dispatch(setPageNo((pageNumber ? pageNumber : pageNo) + 1));
          //setData([...data, ...res]);
        }
      });
    }
  };
  const fetchList = (pageNumber) => {
    appendData(pageNumber);
  };
  useEffect(() => {
    dispatch(getAppointmentDetailsApi(appointmentId));
  }, [appointmentId]);

  useEffect(() => {
    if (appointmentDetails.length > 0) {
      fetchList(1);
    }
  }, [appointmentDetails.length > 0]);
  const onScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      e.currentTarget.scrollHeight - Math.floor(e.currentTarget.scrollTop) ===
      clientHeight
    ) {
      fetchList(pageNo);
    }
    // if (
    //   e.currentTarget.scrollHeight - e.currentTarget.scrollTop - height <=
    //   1
    // ) {
    //   fetchList(pageNo);
    // }
  };
  const selectVisitHistoryDetails = (item) => {
    history.push(`/appointment/${appointmentId}/visithistory/${item.noteID}`);
  };
  const onBackClick = () => {
    dispatch(clearVisitsStateApi());
    history.push(`/`);
  };
  useEffect(() => {
    if (visitId) {
      setSelectedCard(visitId);
    }
  }, [visitId]);
  const showAll = () => {
    setClearFilter(false);
    setPageNo(1);
    dispatch(clearVisitsOnSearchApi());
    if (appointmentDetails.length > 0) {
      const locationid = appointmentDetails[0].location;
      const customerId = appointmentDetails[0].customerID;
      var obj = {
        locationId: locationid,
        customerId: customerId,
        pageNumber: 1,
        pageSize: pageSize,
        startDate: null,
        endDate: null,
        sotsearch: null,
        empsearch: null,
      };
      setSearchinputs(obj);
      dispatch(getVisitHistoryApi(obj)).then((res) => {
        if (res) {
          setData([...data, ...res]);
        }
      });
    }
  };

  const filterCallback = (appointment) => {
    const filterReq = {
      locationId: appointmentDetails[0].location,
      customerId: appointmentDetails[0].customerID,
      pageNumber: 1,
      pageSize: pageSize,
      sotsearch: appointment.sotsearch,
      empsearch: appointment.empsearch,
      startDate: appointment.startDate,
      endDate: appointment.endDate,
    };
    setClearFilter(true);
    setFilterData(filterReq);
  };

  useEffect(() => {
    if (filterData) {
      dispatch(clearVisitsOnSearchApi());
      setFilterinfo(filterData);
      fetchList(1);
    }
  }, [filterData]);
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };
  const { height, width } = useWindowDimensions();
  return (
    <>
      <div>
        <Row className="post-icon">
          <Col span={6} className="text-left">
            <div className="visit-filter-icon">
              <Filters
                screenId={filterConfig}
                filterCallback={filterCallback}
                ClearFilter={clearFilter}
              />
            </div>
          </Col>
          <Col span={9} className="text-center mt-2">
            <h4>
              <FormattedMessage id="Visits.Visits" />
            </h4>
          </Col>
          <Col span={5} className="text-right">
            <Button
              type="link"
              onClick={() => showAll()}
              disabled={!clearFilter}
            >
              <FormattedMessage id="Appointment.Clear" />
            </Button>
          </Col>
          <Col span={4} className="text-right">
            {" "}
            {/* <Link to={`/`}> */}
            <Button type="link" onClick={onBackClick}>
              <i className="iconsminds-back"></i>
            </Button>
            {/* </Link> */}
          </Col>
        </Row>
        {/* <a href="#">
          <i className="iconsminds-filter-2"></i>
        </a> */}
      </div>
      <Spin spinning={loader}>
        {VisitHistory.length == 0 ? (
          <>
            {" "}
            <EmptyContainer
              description={
                clearFilter ? (
                  <FormattedMessage id="Visits.Nomatchfound" />
                ) : (
                  <FormattedMessage id="Visits.NoVisits" />
                )
              }
            />{" "}
          </>
        ) : (
          <VirtualList
            data={VisitHistory}
            height={Math.floor(height / 100) * 100 - 32}
            itemHeight={150}
            style={{ overflow: "auto" }}
            itemKey="email"
            onScroll={onScroll}
          >
            {(item) => (
              <div className="h-100">
                <div className="visithistory-left">
                  <Card
                    size="small"
                    bordered={false}
                    className={selectedCard === item.noteID && "card-active"}
                    onClick={() => selectVisitHistoryDetails(item)}
                  >
                    <div className="appoin-details">
                      <Row>
                        <Col span={16}>
                          <Title level={5} className="text-primary">
                            <i className="iconsminds-male mr-7"></i>
                            {item?.staffMemberName?.trim()}
                          </Title>
                        </Col>

                        <Col span={8} className="text-right">
                          <Title level={5} className="text-primary">
                            {moment
                              .utc(item.scheduledStartDate)
                              .local()
                              .format("M-D-YYYY")}
                          </Title>
                        </Col>
                      </Row>
                    </div>
                    <div className="app-bottom">
                      <Row>
                        <Col>
                          <Paragraph className="text-muted mb-0">
                            {item.staffMemberEmployeeID}
                          </Paragraph>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Paragraph>{item.appointmentNbr}</Paragraph>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </VirtualList>
        )}
      </Spin>
    </>
  );
}

export default VisitHistory;
