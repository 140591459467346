import { getRequest, postRequest } from "app/axiosClient";
import { getStageRecords, resetState } from "./StageSlice";
import { message } from "antd";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getStageRecordsApi = (noteID) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Stage/GetStagesList?NoteID=${noteID}`)
      .then((res) => {
        dispatch(getStageRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Visits.Unablefetchdata",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Visits.Unablefetchdata",
        type: "error",
      })
    );
  }
};

export const updateStageDetailsApi =
  (values, noteId, setChangedData) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Stage/UpdateStages`, values)
        .then((res) => {
          dispatch(getStageRecordsApi(noteId));
          dispatch(hideLoader());
          setChangedData([]);
          dispatch(
            showNotification({
              show: true,
              messageId: "Stage.UpdateSuccess",
              type: "success",
            })
          );
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Stage.UnabletoUpdate",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Stage.UnabletoUpdate",
          type: "error",
        })
      );
    }
  };
export const clearStageStateApi = () => (dispatch) => {
  dispatch(resetState());
};
