import { createSlice } from "@reduxjs/toolkit";

export const NofificationMessagesSlice = createSlice({
  name: "NofificationMessages",
  initialState: {
    messageId: "",
    show: false,
    type: "",
  },

  reducers: {
    showNotification(state, action) {
      state.show = action.payload.show;
      state.messageId = action.payload.messageId;
      state.type = action.payload.type;
    },
  },
});

export const { showNotification } = NofificationMessagesSlice.actions;

export default NofificationMessagesSlice.reducer;
