import React, { Suspense, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FormattedMessage } from "react-intl";
import { Route, Switch, Link, useParams, useLocation } from "react-router-dom";
import routes from "./routes";
import moment from "moment";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Layout,
  Select,
  Tabs,
  Card,
  Dropdown,
  Tag,
  Row,
  Col,
  Modal,
  Table,
  ConfigProvider,
  Typography,
  Button,
  Space,
  Input,
  Form,
  Skeleton,
  List,
  Avatar,
  message,
} from "antd";
import { CalendarOutlined, MenuOutlined } from "@ant-design/icons";
import { Context } from "../components/Wrapper";
import { isAdmin } from "../features/OnBoarding/Authorizaton";
import logo1 from "../logo.png";
import Item from "antd/es/list/Item";
import ReviewAndComplete from "../features/Appointments/ReviewAndComplete";
import CaptureSignature from "../features/Appointments/CaptureSignature/CaptureSignature";
import { getTokenApi, logOutApi } from "../features/OnBoarding/authenticateAPI";
import Appointments from "../features/Appointments/AppointmentList/Appointments";
import VisitHistory from "../features/VisitHistory/VisitHistory";
import SideMenu from "features/SideMenu/SideMenu";
import GlobalSpinner from "features/CommonComponents/GlobalSpinner/GlobalSpinner";
const { Header, Sider, Footer, Content } = Layout;

function ProtectedRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const context = useContext(Context);
  const { tokenDetails } = useSelector((state) => state.authentication);
  const employeefullname = localStorage.getItem("employeefullname");
  const onLogout = () => {
    var selectedLanguage = localStorage.getItem("selectedlanguage");
    localStorage.clear();
    localStorage.setItem(
      "selectedlanguage",
      selectedLanguage ? selectedLanguage : "fr-FR"
    );
    dispatch(logOutApi(localStorage.getItem("access_token")));
  };
  const options = [
    {
      key: "5",
      label: (
        <p className="mb-0">
          <i className="iconsminds-male-2 font-20"></i> {employeefullname}
        </p>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <>
          {context.locale === "en-US" ? (
            <a
              className="text-primary"
              type={context.locale === "fr-FR" ? "primary" : "default"}
              onClick={() => context.selectLang("fr-FR")}
            >
              French
            </a>
          ) : (
            <a
              className="text-primary"
              type={context.locale === "en-US" ? "primary" : "default"}
              onClick={() => context.selectLang("en-US")}
              style={{ marginRight: "8px" }}
            >
              English
            </a>
          )}
        </>
      ),
    },
    // {
    //   key: "1",
    //   label: (
    //     <a
    //       rel="noopener noreferrer"
    //       //href=""
    //     >
    //       <FormattedMessage id="Appointment.CacheClear" />
    //     </a>
    //   ),
    // },

    // {
    //   key: "3",
    //   label: (
    //     <p className="mb-0">
    //       <FormattedMessage id="Appointment.TimeZone" />: UTC−12:00
    //     </p>
    //   ),
    // },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <a rel="noopener noreferrer" href="" onClick={() => onLogout()}>
          <FormattedMessage id="AppointmentStatus.Logout" />
        </a>
      ),
    },
  ];
  const [review, setReview] = useState(false);
  const [signatureCapture, setSignatureCapture] = useState(false);

  const [isActiveAppointment, setActiveAppointment] = useState("false");
  const AppointmentToggleClass = () => {
    setActiveAppointment(!isActiveAppointment);
  };
  const [isActiveMenu, setActiveMenu] = useState(false);
  const MenuToggleClass = () => {
    setActiveMenu(!isActiveMenu);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
          color: "rgba(0, 0, 0, 1)",
        },
      }}
    >
      <Layout>
        <Layout
          className="site-layout"
          style={{
            overflow: "auto",
            minHeight: "calc(100vh - 30px)",
            width: "100%",
            paddingBottom: "0",
            left: 0,
          }}
        >
          <Content
            className="site-layout-background"
            style={{
              margin: "0",
              padding: "0",
              minHeight: 280,
            }}
          >
            <div className="right-width">
              <Row align="middle" className="h-100">
                <Col flex="180px">
                  <img src={logo1} className="normal-logo" alt="" />
                </Col>

                <Col flex="auto" style={{ paddingLeft: "15px" }}>
                  <div
                    className="d-inline-block d-mdnew-none"
                    onClick={MenuToggleClass}
                  >
                    {" "}
                    <MenuOutlined />
                  </div>
                </Col>
                <Col flex="40px">
                  <Dropdown
                    overlayClassName="settings-dropdown"
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={{
                      items: options,
                    }}
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <i className="iconsminds-gear"></i>
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </div>
            <div className={`app-menu ${isActiveAppointment ? "" : "shown"}`}>
              <a
                className="app-menu-button d-inline-block d-mdnew-none"
                onClick={AppointmentToggleClass}
              >
                <i className="simple-icon-options"></i>
              </a>
              {pathname.indexOf("visithistory") > -1 ? (
                <VisitHistory />
              ) : (
                <Appointments />
              )}
            </div>
            <Row className="alltab-main">
              <ReviewAndComplete reiew={review} setReview={setReview} />
              <CaptureSignature
                signatureCapture={signatureCapture}
                setSignatureCapture={setSignatureCapture}
              />
              <Col span={24}>
                <Switch>
                  <Suspense fallback={<div>Loading...</div>}>
                    {routes.map(
                      ({ component: Component, path, exact }, index) => (
                        <Route path={`/${path}`} key={index} exact={exact}>
                          {/* <Component /> */}
                          <GlobalSpinner
                            appContainer={<Component />}
                          ></GlobalSpinner>
                          <div className={`fg ${isActiveMenu ? "shown" : ""}`}>
                            <SideMenu />
                          </div>
                        </Route>
                      )
                    )}
                  </Suspense>
                </Switch>
              </Col>
            </Row>
          </Content>
          {/* <Footer
          style={{
            borderTop: "1px solid #e8e8e8",
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <FormattedMessage id="Routes.footer" />
        </Footer> */}
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default ProtectedRoutes;
