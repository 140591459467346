import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  additionalWorkRecords: [],
};
const AdditionalWorkSlice = createSlice({
  name: "AdditionalWork",
  initialState,
  reducers: {
    getAdditionalWorkRecords: (state, action) => {
      state.additionalWorkRecords = action.payload;
    },
    resetState(state) {
      state.additionalWorkRecords = [];
    },
  },
});
export const { getAdditionalWorkRecords, resetState } =
  AdditionalWorkSlice.actions;
export default AdditionalWorkSlice.reducer;
