import { Empty } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import NodataImg from "../../../nodata.gif";
function EmptyContainer(props) {
  const { loader } = useSelector((state) => state.GlobalSpinner);
  return (
    <>
      {!loader ? (
        <Empty
          // image={NodataImg}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          // imageStyle={{
          //   height: 80,
          // }}
          description={<span>{props.description}</span>}
        ></Empty>
      ) : (
        <></>
      )}
    </>
  );
}
export default EmptyContainer;
