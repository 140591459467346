import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  equipmentDetails: null,
  tasksRecords: [],
  hideUpdateButtonStatus: true,
  equipmentGUID: null,
};

const EquipmentDetailsSlice = createSlice({
  name: "equipmentDetails",
  initialState,
  reducers: {
    equipmentDetailsSuccess(state, action) {
      state.equipmentDetails = action.payload;
    },
    getTasksRecords: (state, action) => {
      state.tasksRecords = action.payload;
    },
    showUpdateButton(state) {
      state.hideUpdateButtonStatus = false;
    },
    hideUpdateButton(state) {
      state.hideUpdateButtonStatus = true;
    },
    clearTasksRecords: (state, action) => {
      state.tasksRecords = [];
    },
    setEquipmentGUID: (state, action) => {
      state.equipmentGUID = action.payload;
    },
    clearEquipmentDetails(state) {
      state.equipmentDetails = null;
      state.tasksRecords = [];
    },
  },
});

export const {
  equipmentDetailsSuccess,
  getTasksRecords,
  showUpdateButton,
  hideUpdateButton,
  clearTasksRecords,
  setEquipmentGUID,
  clearEquipmentDetails,
} = EquipmentDetailsSlice.actions;
export default EquipmentDetailsSlice.reducer;
