import { createSlice } from "@reduxjs/toolkit";
export const LabourSlice = createSlice({
  name: "Labour",
  initialState: {
    labourRecords: [],
    serviceOrderRecords: [],
    loader: false,
  },
  reducers: {
    getLabourRecords: (state, action) => {
      state.labourRecords = action.payload;
    },
    getServiceOrderRecords: (state, action) => {
      state.serviceOrderRecords = action.payload;
    },
  },
});
export const { getLabourRecords, getServiceOrderRecords } = LabourSlice.actions;
export default LabourSlice.reducer;
