import { createSlice } from "@reduxjs/toolkit";
export const StageSlice = createSlice({
  name: "Stage",
  initialState: {
    stageRecords: [],
  },
  reducers: {
    getStageRecords: (state, action) => {
      state.stageRecords = action.payload;
    },
    resetState(state) {
      state.stageRecords = [];
    },
  },
});
export const { getStageRecords, resetState } = StageSlice.actions;
export default StageSlice.reducer;
