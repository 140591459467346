import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import {
  equipmentListSuccess,
  equipmentTypeListSuccess,
  resetState,
  targetEquipmentListSuccess,
  getEquipmentsForCustomer,
} from "./EquipmentsSlice";
import { ClearSummary } from "../../Summary/SummarySlice";
import {} from "./EquipmentsSlice";
import { showResultPopupMessage } from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
//import { getTasksByEquipmentIdApi } from "../../Tasks/TasksAPI";
import { hideUpdateButton } from "../../Tasks/TasksSlice";
import { getTasksByEquipmentIdApi } from "../EquipmentDetails/EquipmentDetailsAPI";
import { showNotification } from "../../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getEquipmentListAPI = (path) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Equipment/GetEquipmentList`, path)
      .then((res) => {
        dispatch(equipmentListSuccess(res.data));
        dispatch(hideLoader());
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
        type: "error",
      })
    );
    return false;
  }
};

export const getEquipmentTypeListAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return getRequest(`api/Equipment/GetEquipmentTypes`)
      .then((res) => {
        dispatch(equipmentTypeListSuccess(res.data));
        // dispatch(hideLoader());
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    return false;
  }
};

export const getTargetEquipmentListAPI = (path) => (dispatch) => {
  try {
    dispatch(showLoader());
    return getRequest(`api/Lookup/GetTargetEquipmentList?${path}`)
      .then((res) => {
        dispatch(targetEquipmentListSuccess(res.data));
        //dispatch(hideLoader());
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    return false;
  }
};

export const addEquipmentAPI = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    return postRequest(`api/Equipment/AddEquipments`, payload)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.EquipmentAddedSuccessfully",
            type: "success",
          })
        );
        return true;
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.EquipmentAddFailed",
            type: "error",
          })
        );
        return false;
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Equipment.EquipmentAddFailed",
        type: "error",
      })
    );
    return false;
  }
};

export const updateEquipmentTaskStatusApi =
  (values, customer) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Tasks/MarkAllTasksAsCompleted`, values)
        .then((res) => {
          let path = `appointmentNbr=${values.appointmentNbr}&customerId=${customer}`;
          const input = {
            appointmentNbr: values.appointmentNbr,
            customerId: customer,
            Tagsearch: null,
            Manufsearch: null,
          };
          dispatch(getEquipmentListAPI(input));
          var selectedEquiInTask = localStorage.getItem("selectedEquipInTask");
          var selectedAppointmentJSON = localStorage.getItem(
            "selectedAppointment"
          );
          const selectedAppointment = JSON.parse(selectedAppointmentJSON);
          //The below dispatch used to fetch the tasks related to that appointment(equipment), when user is in Tasks tab and performs MarkAlltaskCompleted from the options.
          if (
            selectedAppointment.appointmentNbr == values.appointmentNbr &&
            selectedEquiInTask
          ) {
            // dispatch(
            //   getTasksByEquipmentIdApi(
            //     values.appointmentNbr,
            //     selectedEquiInTask
            //   )
            // );
            dispatch(
              getTasksByEquipmentIdApi(
                values.appointmentNbr,
                selectedEquiInTask
              )
            );
            dispatch(hideUpdateButton());
          }
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.EquipmentTaskStatusUpdatedSuccessfully",
              type: "success",
            })
          );
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.EquipmentStatusUpdateFailed",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Equipment.EquipmentStatusUpdateFailed",
          type: "error",
        })
      );
    }
  };

export const CompleteAllTasksApi =
  (appointmentNbr, soType, customer) => (dispatch) => {
    try {
      dispatch(showLoader());
      getRequest(
        `api/Equipment/GetEquipmentIds?appointmentNbr=${appointmentNbr}`
      )
        .then((res) => {
          if (res.data.length > 0) {
            const input = {
              appointmentNbr: appointmentNbr,
              serviceOrderType: soType,
              equipmentIDs: res.data,
            };
            dispatch(updateEquipmentTaskStatusApi(input, customer.trim()));
          } else {
            dispatch(hideLoader());
            dispatch(
              showNotification({
                show: true,
                messageId: "Equipment.AppointmentNoTasksUpdate",
                type: "warning",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.ActionFailed",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Equipment.ActionFailed",
          type: "error",
        })
      );
    }
  };

export const getEquipmentsForCustomerApi =
  (customer, location) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(
        `api/Equipment/GetAllEquipmentsForCustomer?customerId=${customer}&location=${location}`
      )
        .then((res) => {
          dispatch(getEquipmentsForCustomer(res.data));
          //dispatch(hideLoader());
          return true;
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
              type: "error",
            })
          );

          return false;
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Equipment.AnErrorOccurredWhileFetchingDetails",
          type: "error",
        })
      );
      return false;
    }
  };

export const updateServiceTemplateByEquipmentIds =
  (input, customer) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Tasks/CompleteEquipmentTask`, input)
        .then((res) => {
          var path = {
            appointmentNbr: input.appointmentNbr,
            customerId: customer,
            Tagsearch: null,
            Manufsearch: null,
          };
          dispatch(getEquipmentListAPI(path));
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.EquipmentTaskStatusUpdatedSuccessfully",
              type: "success",
            })
          );
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(
            showNotification({
              show: true,
              messageId: "Equipment.EquipmentStatusUpdateFailed",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          show: true,
          messageId: "Equipment.EquipmentStatusUpdateFailed",
          type: "error",
        })
      );
    }
  };

export const clearState = () => (dispatch) =>
  Promise.all([dispatch(resetState()), dispatch(ClearSummary())]);
